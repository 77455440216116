import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import { HistoricalYieldTrendChart } from '@datanac/datanac-visualization-toolkit/dist/Components/rma-adm/HistoricalYieldTrendChart'
import { ProducerFarmCropWithHistoryChart } from '@datanac/datanac-visualization-toolkit/dist/Components/rma-aip-pass/ProducerFarmCropWithHistoryChart'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { Box, CircularProgress, Divider, FormControl, Grid, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AipPassApiHelper, ApiHelper, UsersApiHelper } from 'api/ApiHelper'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import _ from 'lodash'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatPracticeName } from 'utility/formatters'
import { v4 as uuidv4, v4 } from 'uuid'
import BaseAcresCard from './components/BaseAcresCard'
import FieldCard from './components/FieldCard'
import ProducerFarmBaseAcreDataTable from './components/ProducerFarmBaseAcreDataGrid'
import ProducerFarmCropWithHistoryDataGrid, { COLUMNS_PRODUCERFARMCROPWITHHISTORY } from './components/ProducerFarmCropWithHistoryDataGrid'
import ProducerFarmFieldDataTable from './components/ProducerFarmFieldDataTable'
import { CROP_YEAR_POTENTIAL } from './helpers'
import { useFarmController } from './useFarmController'
import { SYSTEM_PROMPT_FARM_EVENTS } from './VaultCurrentWorkspace'
import { FarmEventList } from './components/FarmEventList'
import { ChatAssistantButton } from 'mui-toolkit/ChatAssistantButton'


export default function FarmDetailWorkspace() {
    const { globalState } = useContext(AppContext);
    const {
        crop_year,
        location_state_name, location_county_name,
        producer_farm_id
    } = useParams();
    const navigate = useNavigate();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();
    const [isLoadingFields, setIsLoadingFields] = useState(true);
    const [isLoadingBaseAcres, setIsLoadingBaseAcres] = useState(true);

    // --- --- ---

    const [farmFilter] = useState({
        crop_year
    });
    const {
        isLoadingProducerFarmFieldCrops,
        producerFarmFieldCrops,
    } = useFarmController({
        crop_year: crop_year,
        filter: farmFilter,
        onError: handleError
    });

    const [systemPromptFarmEvents, setSystemPromptFarmEvents] = useState(null);
    useEffect(() => {
        if (producerFarmFieldCrops?.length) {
            // map/filter, removing unwanted fields; only show certain fields:
            const mappedFields = [
                "id",
                "commodity_name", "type_name", "practice_name",
                "approved_yield",
            ]

            setSystemPromptFarmEvents(SYSTEM_PROMPT_FARM_EVENTS + "\n ```json" + JSON.stringify(producerFarmFieldCrops) + '\n```');
        }
    }, [producerFarmFieldCrops])

    const handleOnChangeObject_EventItem = async (_eventItem) => {
        _eventItem.id = v4();
        _eventItem.producer_token = globalState.producer_token;
        _eventItem.crop_year = crop_year;
        _eventItem.year = crop_year;
        _eventItem.is_active = true;
        if (!_eventItem.module) _eventItem.module = "Other";
        if (!_eventItem.category) _eventItem.category = "Other";

        await UsersApiHelper.updateProducerFarmFieldCropEvent(_eventItem);
        _loadProducerFarmFieldCropEvents();
    }

    // ---

    // --- --- ---

    const [state, dispatch] = useReducer(propUpdateReducer, {
        is_active: true
    });
    const loadProducerFarm = async () => {
        try {
            if (location_state_name && location_county_name && producer_farm_id != 0) {
                const producerFilter = {
                    producer_token: globalState.producer_token,
                    ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                    id: producer_farm_id,
                    is_active: true
                };
                const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
                if (farms?.length) {
                    dispatch({
                        type: 'updateMany',
                        payload: farms[0]
                    });
                }
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            dispatch({
                type: 'replace',
                payload: {}
            });
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && producer_farm_id != 0) {
            loadProducerFarm();
        } else {
            setIsLoading(false);
        }
    }, [location_state_name, location_county_name, producer_farm_id,
        globalState?.producer_token]);

    // --- --- ---

    const [fieldsWorkspaceMode, setFieldsWorkspaceMode] = useState(WORKSPACE_VIEW_CARD);
    const { state: fields, actions: fieldActions } = useCardController([]);
    const loadProducerFarmFields = async () => {
        try {
            const fieldsFilter = {
                producer_token: globalState.producer_token,
                producer_farm_id: state?.id,
                is_active: true
            };
            const fields = await UsersApiHelper.selectProducerFarmFields(fieldsFilter, globalState);
            fieldActions.replace(fields);

            setIsLoadingFields(false);
        } catch (err) {
            setIsLoadingFields(false);
            fieldActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (state?.id) {
            loadProducerFarmFields();
        }
    }, [state?.id, globalState?.producer_token])

    // --- --- ---

    const [baseAcresWorkspaceMode, setBaseAcresWorkspaceMode] = useState(WORKSPACE_VIEW_CARD);
    const { state: baseAcres, actions: baseAcreActions } = useCardController([]);
    const loadBaseAcres = async () => {
        try {
            const baseAcresFilter = {
                producer_token: globalState.producer_token,
                producer_farm_id: state?.id,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(baseAcresFilter, globalState);
            baseAcreActions.replace(baseAcres);

            setIsLoadingBaseAcres(false);
        } catch (err) {
            setIsLoadingBaseAcres(false);
            baseAcreActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (state?.id && state?.id != 0 && globalState?.producer_token) {
            loadBaseAcres();
        }
    }, [state?.id, globalState?.producer_token])

    // --- --- ---

    const { state: history, actions: historyActions } = useCardController([]);
    const loadProducerFarmCropWithHistory = async () => {
        try {
            const historyFilter = {
                producer_token: globalState.producer_token,

                location_state_name,
                location_county_name,
                fsa_farm_number: state?.fsa_farm_number,

                yield_type_code: 'A'
            };
            const history = (await AipPassApiHelper.selectProducerFarmCropWithHistory(historyFilter, globalState)).map(
                (h, index) => ({ id: index, ...h })
            );
            history.forEach((h, index) => {
                h.id = index;
                h.unique_key = JSON.stringify(
                    COLUMNS_PRODUCERFARMCROPWITHHISTORY.map(col => h[col?.field])
                );
            });
            const _historyUnique = _.uniqBy(history, "unique_key");
            historyActions.replace(_historyUnique);
        } catch (err) {
            historyActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && location_state_name && location_county_name
            && producer_farm_id && state?.fsa_farm_number
        ) {
            loadProducerFarmCropWithHistory();
        }
    }, [location_state_name, location_county_name,
        producer_farm_id, state?.fsa_farm_number,
        globalState?.producer_token,])

    // ---

    // const { state: producerFarmFieldCrops, actions: producerFarmFieldCropActions } = useCardController();
    // const loadProducerFarmFieldCrop = async () => {
    //     try {
    //         const producerFilter = {
    //             producer_token: globalState.producer_token,
    //             location_state_name,
    //             location_county_name,
    //             ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
    //             is_active: true
    //         };
    //         const farms = await UsersApiHelper.selectProducerFarmFieldCrops(producerFilter, globalState);
    //         producerFarmFieldCropActions.replace(farms);

    //         setIsLoading(false);
    //     } catch (err) {
    //         setIsLoading(false);
    //         producerFarmFieldCropActions.replace([]);
    //         console.warn(err);
    //         enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
    //     }
    // }

    // useEffect(() => {
    //     if (globalState?.producer_token) {
    //         loadProducerFarmFieldCrop();
    //     } else {
    //         setIsLoading(false);
    //     }
    // }, [globalState?.producer_token]);

    // ---

    // --- --- ---

    const { state: producerFarmField_union_Crops, actions: producerFarmField_union_CropActions } = useCardController();

    useEffect(() => {
        if (fields?.length && !producerFarmFieldCrops?.length) {
            producerFarmField_union_CropActions.replace(fields);
        } else if (fields?.length
            && (producerFarmFieldCrops?.length || producerFarmFieldCrops?.length)) {
            const loadUnionProducerFarmFieldCrops = async () => {
                // Union:
                const _producerFarmFieldsUnion = [...fields];
                _producerFarmFieldsUnion.forEach(currentProducerFarmField => {
                    currentProducerFarmField.ProducerFarmFieldCrops = producerFarmFieldCrops.filter(crop =>
                        crop.producer_farm_id == currentProducerFarmField.producer_farm_id
                        && crop.producer_farm_field_id == currentProducerFarmField.id
                    );

                    currentProducerFarmField.CropProfiles = _.uniqBy(currentProducerFarmField?.ProducerFarmFieldCrops, pfc =>
                        JSON.stringify({
                            commodity_name: pfc.commodity_name,
                            type_name: pfc.type_name,
                            practice_name: pfc.practice_name,
                        }))
                        ?.sort((a, b) =>
                            a.commodity_name?.localeCompare(b.commodity_name)
                            || a.type_name?.localeCompare(b.type_name)
                            || a.practice_name?.localeCompare(b.typpractice_namee_name)
                        );
                    currentProducerFarmField.CropProfiles_Sort = currentProducerFarmField.CropProfiles?.reduce((accumulator, currentValue) =>
                        accumulator + ", " + currentValue?.commodity_name + "-" + formatPracticeName(currentValue?.practice_name)
                        , "");
                });

                producerFarmField_union_CropActions.replace(_producerFarmFieldsUnion);
            }
            loadUnionProducerFarmFieldCrops();
        } else {
            producerFarmField_union_CropActions.replace([]);
            setIsLoading(false);
        }
    }, [globalState?.producer_token, fields, producerFarmFieldCrops]);

    // --- --- ---

    useEffect(() => {
        if (producer_farm_id == 0) {
            const _producer_farm_id = uuidv4();
            dispatch({
                type: 'update',
                payload: { key: "id", value: _producer_farm_id },
            });
        } else {
            dispatch({
                type: 'update',
                payload: { key: "id", value: producer_farm_id },
            });
        }
    }, [producer_farm_id]);

    useEffect(() => {
        dispatch({
            type: 'updateMany',
            payload: {
                location_state_name,
                location_county_name
            },
        });
    }, [location_state_name, location_county_name])

    const isValid = () => {
        return (
            state?.id
            && state.id != 0
            && state?.fsa_farm_number
            && state?.farm_name
        );
    }
    useBlockerPrompt(!isValid(), "ProducerFarm");

    useEffect(() => {
        if (isValid() && globalState.producer_token && formStatusState?.isFormDirty) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateProducerFarm(state, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }, [state])

    // ---

    const [historyCropProfiles, setHistoryCropProfiles] = useState(null);
    const [historySelectedCropProfile, setHistorySelectedCropProfiles] = useState(null);
    const [selectedCountyPracticeHistory, setSelectedCountyPracticeHistory] = useState(null);

    const loadHistoryCropProfiles = async () => {
        const _historyCropProfiles =
            _.uniqBy(history, pfc =>
                JSON.stringify({
                    commodity_name: pfc.commodity_name,
                    type_name: pfc.type_name,
                    practice_name: pfc.practice_name,
                }))
                ?.map(x => ({
                    reinsurance_year: getCurrentReinsuranceYear(),
                    commodity_name: x.commodity_name,
                    type_name: x.type_name,
                    practice_name: x.practice_name,
                    state_name: x.location_state_name,
                    county_name: x.location_county_name
                }))
                ?.sort((a, b) =>
                    a.commodity_name?.localeCompare(b.commodity_name)
                    || a.type_name?.localeCompare(b.type_name)
                    || a.practice_name?.localeCompare(b.practice_name)
                );
        setHistoryCropProfiles(_historyCropProfiles);
    }

    useEffect(() => {
        if (history?.length) {
            loadHistoryCropProfiles();
        }
    }, [history?.length]);

    useEffect(() => {
        if (historyCropProfiles?.length) {
            setHistorySelectedCropProfiles(historyCropProfiles[0]);
        }
    }, [historyCropProfiles?.length])

    const handleChangeHistorySelectedCropProfileSelect = (event) => {
        setHistorySelectedCropProfiles(JSON.parse(event.target.value));
    }

    const loadSelectedCountyPracticeHistory = async () => {
        const _filteredHistory = history?.
            filter(h =>
                h.commodity_name == historySelectedCropProfile.commodity_name
                && h.type_name == historySelectedCropProfile.type_name
                && h.practice_name == historySelectedCropProfile.practice_name
            );
        if (_filteredHistory?.length) {
            setSelectedCountyPracticeHistory(_filteredHistory);
        } else {
            setSelectedCountyPracticeHistory(null);
        }
    }

    useEffect(() => {
        if (history?.length && historySelectedCropProfile) {
            loadSelectedCountyPracticeHistory();
        }
    }, [history?.length, historySelectedCropProfile])

    // --- --- ---

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    const handleFieldOnChange = (currentField) => {
        if (currentField?.id) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateProducerFarmField(currentField, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadProducerFarmFields();
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    const handleBaseAcresOnChange = (currentBaseAcres) => {
        if (currentBaseAcres?.id) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateProducerFarmBaseAcres({
                ProducerFarmBaseAcres: [{
                    ...currentBaseAcres,
                    producer_token: globalState.producer_token,
                    agent_token: globalState?.agent_token
                }]
            }, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadBaseAcres();
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    const handleAddNewBaseAcresClick = () => {
        navigate(`/vault/${crop_year}/${location_state_name}/${location_county_name}/${state?.id}/baseAcre/0`);
    }

    const handleAddNewFarmClick = () => {
        navigate(`/vault/${crop_year}/${location_state_name}/${location_county_name}/${state?.id}/0`);
    }

    return (
        <>
            <WorkspaceHeader
                title={state?.farm_name || state?.fsa_farm_number || "Farm"}
                breadCrumbs={[
                    {
                        title: (crop_year != CROP_YEAR_POTENTIAL ? 'Planted Acres' : 'Farm Potential')
                            + ((crop_year != CROP_YEAR_POTENTIAL && " - " + crop_year) || ''),
                        path: '/vault' + (crop_year == CROP_YEAR_POTENTIAL ? "/potential" : ""),
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                    {
                        title: location_county_name + ", " + location_state_name,
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}`,
                        icon: null
                    },
                ]}
            />


            <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Farm Details
                </Typography>
                {formStatusComponent}
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={3}>
                    <SkeletonWrapper isLoading={isLoading}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Farm Name"
                                name="farm_name"
                                isRequired={true}
                                defaultValue={state.farm_name || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </SkeletonWrapper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <SkeletonWrapper isLoading={isLoading}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="FSN #"
                                name="fsa_farm_number"
                                isRequired={true}
                                is
                                defaultValue={state.fsa_farm_number || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </SkeletonWrapper>
                </Grid>
            </Grid>

            <Divider />

            {fieldsWorkspaceMode == WORKSPACE_VIEW_CARD && <>
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Fields
                    </Typography>
                    <WorkspaceViewSelector workspaceMode={fieldsWorkspaceMode} setWorkspaceMode={setFieldsWorkspaceMode} />
                </Stack>
                <Box className='cardContainer'>
                    {producerFarmField_union_Crops.map((currentProducerFarmField, index) =>
                        <FieldCard key={index} producerFarm={state} producerFarmField={currentProducerFarmField}
                            onChange={handleFieldOnChange} />
                    )}
                    {!Boolean(fields?.length) &&
                        <EmptyCard
                            text="No fields listed"
                            secondaryText="Get started by adding a field"
                            isLoading={isLoadingFields}
                        />}
                </Box>
            </>}
            {fieldsWorkspaceMode == WORKSPACE_VIEW_TABLE && <>
                <ProducerFarmFieldDataTable producerFarm={state} producerFarmFields={producerFarmField_union_Crops}
                    title={
                        <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                            <Typography variant='h1'>
                                Fields
                            </Typography>
                            <WorkspaceViewSelector workspaceMode={fieldsWorkspaceMode} setWorkspaceMode={setFieldsWorkspaceMode} />
                        </Stack>
                    } />
            </>}

            <Divider />

            {baseAcresWorkspaceMode == WORKSPACE_VIEW_CARD && <>
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Base Acres
                    </Typography>
                    <WorkspaceViewSelector workspaceMode={baseAcresWorkspaceMode} setWorkspaceMode={setBaseAcresWorkspaceMode} />
                </Stack>
                <Box className='cardContainer'>
                    {baseAcres.map((currentBaseAcre, index) =>
                        <BaseAcresCard key={index} producerFarm={state} baseAcres={currentBaseAcre} onChange={handleBaseAcresOnChange} />
                    )}
                    {!Boolean(baseAcres?.length) &&
                        <EmptyCard
                            text="No base acres listed"
                            secondaryText="Get started by adding base acres"
                            isLoading={isLoadingBaseAcres}
                        />}
                </Box>
            </>}
            {baseAcresWorkspaceMode == WORKSPACE_VIEW_TABLE &&
                <ProducerFarmBaseAcreDataTable producerFarm={state} producerFarmBaseAcres={baseAcres}
                    title={
                        <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                            <Typography variant='h1'>
                                Base Acres
                            </Typography>
                            <WorkspaceViewSelector workspaceMode={baseAcresWorkspaceMode} setWorkspaceMode={setBaseAcresWorkspaceMode} />
                        </Stack>
                    } />
            }

            {Boolean(history?.length) && <>
                <Divider />
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Farm History
                    </Typography>
                    {historyCropProfiles?.length && historySelectedCropProfile && <>
                        <Select
                            labelId='historySelectedCropProfileLabel'
                            value={JSON.stringify(historySelectedCropProfile)}
                            name={"historySelectedCropProfile"}
                            onChange={handleChangeHistorySelectedCropProfileSelect}
                            label={'Crop'}
                        >
                            {historyCropProfiles?.map((currentItem, index) => {
                                return <MenuItem key={index} value={JSON.stringify(currentItem)}>
                                    {currentItem.commodity_name + " "}
                                    / {currentItem.type_name == "No Type Specified" ? "" : currentItem.type_name + " / "}
                                    {currentItem.practice_name}
                                </MenuItem>
                            })}
                        </Select>
                    </>}
                </Stack>
                <ProducerFarmCropWithHistoryDataGrid producerFarmCropWithHistory={selectedCountyPracticeHistory || []} />
            </>}

            {historySelectedCropProfile && <>
                <Typography variant='h1'>
                    Farm / County Comparison
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <HistoricalYieldTrendChart
                            countyPractice={
                                {
                                    reinsurance_year: (crop_year != CROP_YEAR_POTENTIAL ? crop_year : getCurrentReinsuranceYear()),
                                    commodity_name: historySelectedCropProfile?.commodity_name,
                                    type_name: historySelectedCropProfile?.type_name,
                                    practice_name: historySelectedCropProfile?.practice_name,
                                    state_name: location_state_name,
                                    county_name: location_county_name
                                }}
                            yield_year_minimum={null}
                            yield_year_maximum={null}
                            api={ApiHelper} size={null} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProducerFarmCropWithHistoryChart
                            countyPractice={
                                {
                                    reinsurance_year: (crop_year != CROP_YEAR_POTENTIAL ? crop_year : getCurrentReinsuranceYear()),
                                    commodity_name: historySelectedCropProfile?.commodity_name,
                                    type_name: historySelectedCropProfile?.type_name,
                                    practice_name: historySelectedCropProfile?.practice_name,
                                    state_name: location_state_name,
                                    county_name: location_county_name
                                }}
                            producerFarmCropWithHistory={selectedCountyPracticeHistory}
                            yield_year_minimum={null}
                            yield_year_maximum={null}
                            api={ApiHelper} size={null} />
                    </Grid>
                </Grid>
            </>}

            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleAddNewBaseAcresClick}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        Add Base Acres
                    </Button>
                    <Button variant="contained" onClick={handleAddNewFarmClick}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Field
                    </Button>
                </Stack>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
