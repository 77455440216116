import { ProducerFarmHelper } from '@datanac/datanac-api-toolkit'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import PrintOutlined from '@mui/icons-material/PrintOutlined'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import { Box, CircularProgress, Dialog, Divider, Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProducerFarmFieldCropDialog } from './ProducerFarmFieldCropDialog'
import FarmCountyCard from './components/FarmCountyCard'
import ImportUploadFileDialog from './components/Import/ImportUploadFileDialog'
import ProducerFarmCopyAipPassDialog from './components/Import/ProducerFarmCopyAipPassDialog'
import ProducerFarmCopyDialog from './components/Import/ProducerFarmCopyDialog'
import { getUniqueCounties } from './helpers'
import { useFarmController } from './useFarmController'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmEventList } from './components/FarmEventList'
import { ChatAssistantButton } from 'mui-toolkit/ChatAssistantButton'
import { v4 } from 'uuid'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { USER_ROLE_ADMIN } from 'core/useSession'
import Drawer from '@mui/material/Drawer'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import ResponsiveButton from './ResponsiveButton'

export default function VaultCurrentWorkspace() {
    const producerFarmHelper = new ProducerFarmHelper();

    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [isCopyYearDialogOpen, setIsCopyYearDialogOpen] = useState(false);
    const handleCopyYearDialogClose = (_crop_year) => {
        setIsCopyYearDialogOpen(false);
        if (_crop_year && _crop_year != currentYear) {
            setCurrentYear(_crop_year);
        } else {
            loadCurrent();
        }
    }

    const [isCopyAipPassDialogOpen, setIsCopyAipPassDialogOpen] = useState(false);
    const handleCopyAipPassDialogClose = (_crop_year) => {
        setIsCopyAipPassDialogOpen(false);
        if (_crop_year) {
            setCurrentYear(_crop_year);
        } else {
            loadCurrent();
        }
    }

    // ---

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    // ---

    const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();


    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    // --- --- ---

    const [farmControllerFilter, dispatchFarmControllerFilter] = useReducer(propUpdateReducer, {});
    const {
        isLoadingProducerFarmFieldCrops,
        producerFarmFieldCrops,

        isLoadingProducerFarmFieldCropEvents,
        producerFarmFieldCropEvents,
        _loadProducerFarmFieldCropEvents,
    } = useFarmController({
        crop_year: currentYear,
        filter: farmControllerFilter,
        doLoadProducerFarmFieldCropEvents: true,/* Load Events */
        onError: handleError
    });

    const [systemPromptFarmEvents, setSystemPromptFarmEvents] = useState(null);
    useEffect(() => {
        if (producerFarmFieldCrops?.length) {
            // map/filter, removing unwanted fields; only show certain fields:
            const mappedFields = [
                "id",
                "commodity_name", "type_name", "practice_name",
                "approved_yield",
            ]

            setSystemPromptFarmEvents(SYSTEM_PROMPT_FARM_EVENTS + "\n ```json" + JSON.stringify(producerFarmFieldCrops) + '\n```');
        }
    }, [producerFarmFieldCrops])

    const handleOnChangeObject_EventItem = async (_eventItem) => {
        _eventItem.id = v4();
        _eventItem.producer_token = globalState.producer_token;
        _eventItem.crop_year = currentYear;
        _eventItem.is_active = true;
        _eventItem.year = currentYear;
        if (!_eventItem.module) _eventItem.module = "Other";
        if (!_eventItem.category) _eventItem.category = "Other";

        await UsersApiHelper.updateProducerFarmFieldCropEvent(_eventItem);
        _loadProducerFarmFieldCropEvents();
    }

    // ---

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(true);
    const { state: currentCards, actions: currentActions } = useCardController();
    const loadCurrent = async () => {
        try {
            setIsLoadingCurrent(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year: currentYear,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            const countyPractices = getUniqueCounties(farms);
            currentActions.replace(countyPractices);

            setIsLoadingCurrent(false);
        } catch (err) {
            setIsLoadingCurrent(false);
            currentActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadCurrent();
        }
    }, [currentYear, globalState?.producer_token]);

    const handlePrintClick = async () => {
        navigate(`./print/${currentYear}`)
    }

    // ---

    const [isAddCropDialogOpen, setIsAddCropDialogOpen] = useState(false);

    const anchor = 'right';

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open });
    };

    // ---

    return (
        <>
            <WorkspaceHeader
                title='Farm Vault'
                breadCrumbs={[{
                    title: 'Farm Vault',
                    // path: '/vault',
                    icon: <FarmVaultIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12} lg={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                        <Typography variant='h1'>
                            Planted Acres
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)}>
                                        {currentYear} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {getBudgetYears().map((year, index) =>
                                            <MenuItem key={index} onClick={() => {
                                                setCurrentYear(year);
                                                popupState.close();
                                            }}>
                                                {year}
                                            </MenuItem>)}
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                        {isLoadingCurrent && <CircularProgress />}
                        
                        <ResponsiveButton onClick={toggleDrawer(anchor, true)} />
                    </Stack>

                    {/* Mobile View Button */}
                    <ResponsiveButton onClick={toggleDrawer(anchor, true)} />

                    <Box className='cardContainer'>
                        {currentCards.map((currentCountyPractice, index) =>
                            <FarmCountyCard currentCounty={currentCountyPractice} crop_year={currentYear} key={index} />
                        )}
                        {!Boolean(currentCards?.length) &&
                            <EmptyCard
                                text="No farms listed"
                                secondaryText="Get started by importing history or adding a farm"
                                isLoading={isLoadingCurrent}
                            />}
                    </Box>
                </Grid>

                {globalState?.user_role == USER_ROLE_ADMIN && isMobile && <Divider flexItem />}
                {Boolean(producerFarmFieldCropEvents?.length || isLoadingProducerFarmFieldCropEvents) && <>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        className='farm-event-drawer'
                    >
                        <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                            <Typography variant='h1'>
                                Farm Events
                            </Typography>
                            {systemPromptFarmEvents && <ChatAssistantButton systemPrompt={systemPromptFarmEvents} onChangeObject={handleOnChangeObject_EventItem} />}
                            {isLoadingProducerFarmFieldCropEvents && <CircularProgress />}

                            <CloseOutlined className='drawer-close-btn' sx={{ mr: 1 }} onClick={toggleDrawer(anchor, false)} />
                        </Stack>
                        <Divider />
                        <FarmEventList producerFarmFieldCropEvents={producerFarmFieldCropEvents} />
                    </Drawer>
                </>}
            </Grid>


            <WorkspaceActionBar content={<>
                <Button variant="contained" onClick={() => setIsAddCropDialogOpen(true)}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Add {!isSmall && " Crop"}
                </Button>
                {/* {[USER_ROLE_ADMIN, USER_ROLE_AGENT].includes(globalState?.user_role) && */}
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="contained" {...bindTrigger(popupState)}>
                                <ArrowDropDownOutlined sx={{ mr: 1 }} />
                                Import
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => {
                                    setIsCopyYearDialogOpen(true);
                                    popupState.close();
                                }}>
                                    <ContentCopyOutlined sx={{ mr: 1 }} /> Copy Prior Season ...
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    setIsUploadFileDialogOpen(true);
                                    popupState.close();
                                }}>
                                    <UploadFileOutlined sx={{ mr: 1 }} /> Upload file ...
                                </MenuItem>

                                <Divider />

                                <MenuItem onClick={() => {
                                    setIsCopyAipPassDialogOpen(true);
                                    popupState.close();
                                }}>
                                    <CloudDownloadOutlined sx={{ mr: 1 }} /> Import from History ...
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </PopupState>

                <Button variant="contained" onClick={handlePrintClick}>
                    <PrintOutlined />
                </Button>
            </>} />


            {isAddCropDialogOpen && <Dialog open={isAddCropDialogOpen} PaperProps={{ sx: { minHeight: "5in" } }}>
                <ProducerFarmFieldCropDialog
                    crop_year={currentYear}
                    location_state_name={null}
                    location_county_name={null}
                    onClose={() => { setIsAddCropDialogOpen(false); loadCurrent(); }}
                />
            </Dialog>}


            {isCopyYearDialogOpen && <Dialog open={isCopyYearDialogOpen} fullWidth={isSmall} PaperProps={{ sx: { minHeight: "80vh" } }}>
                <ProducerFarmCopyDialog onClose={handleCopyYearDialogClose} />
            </Dialog>}

            {isUploadFileDialogOpen && <Dialog open={isUploadFileDialogOpen} fullWidth={isSmall} PaperProps={{ sx: {} }}>
                <ImportUploadFileDialog year={currentYear} onClose={() => { setIsUploadFileDialogOpen(false); loadCurrent(); }} />
            </Dialog>}

            {isCopyAipPassDialogOpen && <Dialog open={isCopyAipPassDialogOpen} fullWidth={isSmall} PaperProps={{ sx: { minHeight: "80vh" } }}>
                <ProducerFarmCopyAipPassDialog onClose={handleCopyAipPassDialogClose} />
            </Dialog>}

            {confirmDialogComponent}
        </>
    )
}

export const SYSTEM_PROMPT_FARM_EVENTS = `
You are a customer service agent, responsible for adding customer records into "Tilley" Farm Financial Software.
Please listen for details on new accounts and return the best available information in JSON format, matching the schema provided below. Only return new or affected records.

### Return values:
- Return only JSON. Do not quote the response.
- To delete a record, set "is_active" to "false".  
- Set "id" to "null".
- Set "producer_token" to "null".
- Set "crop_year" to "null".
- Set "prior_value" to "null".
- Set image URLs to "null".

### Farm fields:
Assign these values from the ProducerFarmFieldCrop records:
- producer_farm_field_crop_id (get from ProducerFarmFieldCrop.id field)
- commodity_name, type_name, practice_name
- approved_yield

### Required fields:
Please provide the most likely choice for these required fields:
- category
- module
- notes

**module** can be one of: 
- "Vault"
- "Budgets"
- "Insurance"
- "Financials"
- "Other"

**category** can be one of:
Vault:
- Production (for agronomic activities)
- Yields
- Planting
- Harvest
- Fertilizer
- Seed
- Other
Budgets:
- Expense
- Revenue
- Other
Insurance:
- Insurance
- Claim
- Payment
- Other
Financials:
- Note
- Other

### SAMPLE RESPONSE:
'''json
[
  {
    "id": "E7D9637E-9815-45AB-BF34-3113A9C40E93",
    "producer_farm_field_crop_id": "9D35EB56-01E1-4D1B-BF17-5DEB8C51B6E5",
    "producer_token": "2DBB47C1-E08A-4203-82A2-E3C33FB0C666",
    "agent_token": "123E4567-E89B-12D3-A456-426614174003",
    "crop_year": 2023,
    "module": "Financial",
    "category": "Expense",
    "notes": "Fertilizer costs for the season",
    "reported_acreage": 120.5,
    "commodity_name": "Corn",
    "type_name": "Yellow Dent",
    "practice_name": "No-Till",
    "approved_yield": 180.5,
    "yield": 175,
    "revenue_amount": 50000,
    "expense_amount": 15000,
    "planned_expense_amount": 14000,
    "image_primary_url": "http://example.com/image1.jpg",
    "image_secondary_url": "http://example.com/image2.jpg",
    "is_active": true,
    "CreateDate": "2024-08-04T22:16:56.653",
    "LastUpdate": "2024-08-04T22:16:58.940"
  },
  {
    "id": "F3A6BFED-33E0-4BBC-8223-AE80BC1A6ADC",
    "producer_farm_field_crop_id": "9D35EB56-01E1-4D1B-BF17-5DEB8C51B6E5",
    "producer_token": "2DBB47C1-E08A-4203-82A2-E3C33FB0C666",
    "agent_token": "123E4567-E89B-12D3-A456-426614174003",
    "crop_year": 2023,
    "module": "Financial",
    "category": "Expense",
    "notes": "Fertilizer costs for the season",
    "reported_acreage": 120.5,
    "commodity_name": "Corn",
    "type_name": "Yellow Dent",
    "practice_name": "No-Till",
    "approved_yield": 180.5,
    "yield": 175,
    "revenue_amount": 50000,
    "expense_amount": 15000,
    "planned_expense_amount": 14000,
    "image_primary_url": "http://example.com/image1.jpg",
    "image_secondary_url": "http://example.com/image2.jpg",
    "is_active": true,
    "CreateDate": "2024-08-04T12:00:00",
    "LastUpdate": "2024-08-04T12:00:00"
  },
  {
    "id": "DE364CF7-AFF7-4D3F-9670-AA60A1AAA9A0",
    "producer_farm_field_crop_id": "9D35EB56-01E1-4D1B-BF17-5DEB8C51B6E5",
    "producer_token": "2DBB47C1-E08A-4203-82A2-E3C33FB0C666",
    "agent_token": "123E4567-E89B-12D3-A456-426614174007",
    "crop_year": 2023,
    "module": "Operational",
    "category": "Yield",
    "notes": "Harvest results",
    "reported_acreage": 95,
    "commodity_name": "Wheat",
    "type_name": "Soft Red",
    "practice_name": "Conventional",
    "approved_yield": 120,
    "yield": 118,
    "revenue_amount": 35000,
    "expense_amount": 10000,
    "planned_expense_amount": 9000,
    "image_primary_url": "http://example.com/image3.jpg",
    "image_secondary_url": "http://example.com/image4.jpg",
    "is_active": true,
    "CreateDate": "2023-07-15T10:30:00",
    "LastUpdate": "2023-07-15T10:30:00"
  },
  {
    "id": "51FB6690-BBAB-49EB-B7F3-2E5FDD8A7AA7",
    "producer_farm_field_crop_id": "9D35EB56-01E1-4D1B-BF17-5DEB8C51B6E5",
    "producer_token": "2DBB47C1-E08A-4203-82A2-E3C33FB0C666",
    "agent_token": "123E4567-E89B-12D3-A456-426614174011",
    "crop_year": 2023,
    "module": "Financial",
    "category": "Revenue",
    "notes": "Sales of harvested crop",
    "reported_acreage": 150,
    "commodity_name": "Soybeans",
    "type_name": "Glycine Max",
    "practice_name": "Organic",
    "approved_yield": 60,
    "yield": 58,
    "revenue_amount": 45000,
    "expense_amount": 20000,
    "planned_expense_amount": 18000,
    "image_primary_url": "http://example.com/image5.jpg",
    "image_secondary_url": "http://example.com/image6.jpg",
    "is_active": true,
    "CreateDate": "2022-11-20T08:45:00",
    "LastUpdate": "2022-11-20T08:45:00"
  },
  {
    "id": "8205E21A-2F4D-4625-95C3-945C32F1C96D",
    "producer_farm_field_crop_id": "9D35EB56-01E1-4D1B-BF17-5DEB8C51B6E5",
    "producer_token": "2DBB47C1-E08A-4203-82A2-E3C33FB0C666",
    "agent_token": "123E4567-E89B-12D3-A456-426614174015",
    "crop_year": 2023,
    "module": "Operational",
    "category": "Planting",
    "notes": "Spring planting completed",
    "reported_acreage": 130,
    "commodity_name": "Rice",
    "type_name": "Jasmine",
    "practice_name": "Flooded",
    "approved_yield": 100,
    "yield": 95,
    "revenue_amount": 55000,
    "expense_amount": 12000,
    "planned_expense_amount": 11000,
    "image_primary_url": "http://example.com/image7.jpg",
    "image_secondary_url": "http://example.com/image8.jpg",
    "is_active": true,
    "CreateDate": "2024-04-25T14:20:00",
    "LastUpdate": "2024-04-25T14:20:00"
  }
]
'''

## ProducerFarmFieldCrop records:
`;