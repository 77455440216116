import React from 'react';
import { Button } from '@mui/material';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';

const ResponsiveButton = ({ onClick }) => {
    return (
        <Button
            className='farm-event-btn'
            variant="contained"
            onClick={onClick}
        >
            <VisibilityOutlined sx={{ mr: 1 }} />
            Farm Events
        </Button>
    );
};

export default ResponsiveButton;
