import { ApiHelper, UsersApiHelper } from "api/ApiHelper";
import { getUniqueBaseAcreCrops } from "components/Vault/Farm/helpers";
import { calculateArcPlc } from '@datanac/datanac-api-toolkit/dist/arcplc/arcplc';
import { MODULE_ARC_PLC } from "components/Menu/NavigationMenu";

export const PROGRAM_NAME_ARCCO = "arc-co";
export const PROGRAM_NAME_PLC = "plc";

export const PRACTICE_NO_PRACTICE_SPECIFIED = "No Practice Specified";

export const MARKET_YEAR_AVERAGE_PRICE_STATUS_PROJECTED = "P";

export function ArcPlcService({
    apiConfig,

    crop_year,

    baseAcres,
    location_state_name,
    location_county_name,
}) {

    const loadBaseAcres = async () => {
        const baseAcresFilter = {
            crop_year,
            location_state_name,
            location_county_name,
            is_active: true
        };
        const baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(baseAcresFilter, apiConfig);
        return baseAcres;
    }

    const loadProgramElections = async () => {
        const programElectionFilter = {
            crop_year,
            program_category: MODULE_ARC_PLC,
            is_active: true
        };
        const _programElections = await UsersApiHelper.users_selectObject("ProgramElection", programElectionFilter, apiConfig);
        return _programElections;
    }

    const selectArcPlc = async (currentBaseAcres) => {
        if (
            currentBaseAcres?.crop_year
            && currentBaseAcres?.commodity_name
            && currentBaseAcres?.base_acreage && currentBaseAcres?.plc_yield
            && (currentBaseAcres?.state_name || currentBaseAcres?.location_state_name)
            && (currentBaseAcres?.county_name || currentBaseAcres?.location_county_name)
        ) {
            const _arcPlcRequest = {
                crop_year: currentBaseAcres?.crop_year,
                state_name: (currentBaseAcres?.state_name || currentBaseAcres?.location_state_name),
                county_name: (currentBaseAcres?.county_name || currentBaseAcres?.location_county_name),
                commodity_name: currentBaseAcres?.commodity_name,
                base_acreage: currentBaseAcres?.base_acreage,
                plc_yield: currentBaseAcres?.plc_yield,
                apiHelper: ApiHelper,
            };
            const arcPlcResponse = await calculateArcPlc(_arcPlcRequest);
            return arcPlcResponse;
        } else {
            console.warn("ARC/PLC not ready!", currentBaseAcres);
        }
    }

    // --- --- ---

    const getPLCPaymentRates = async () => {
        if (!baseAcres?.length) {
            baseAcres = await loadBaseAcres();
        }
        const _uniqueCropBaseAcres = getUniqueBaseAcreCrops(baseAcres);

        for (const _currentCrop of _uniqueCropBaseAcres) {
            const _fsaDataFilter = {
                crop_year: _currentCrop?.crop_year,
                commodity_name: _currentCrop?.commodity_name
            };
            var _plcPaymentRates = await ApiHelper.selectObject("FSA.ARCPLC", "PLCPaymentRates", _fsaDataFilter);
            _currentCrop.PLCPaymentRates = _plcPaymentRates;
        }

        return _uniqueCropBaseAcres;
    }

    // --- --- ---

    const getEffectiveReferencePrices = async () => {
        if (!baseAcres?.length) {
            baseAcres = await loadBaseAcres();
        }
        const _uniqueCropBaseAcres = getUniqueBaseAcreCrops(baseAcres);

        for (const _currentCrop of _uniqueCropBaseAcres) {
            const _fsaDataFilter = {
                crop_year: _currentCrop?.crop_year,
                commodity_name: _currentCrop?.commodity_name
            };
            var _effectiveReferencePrices = await ApiHelper.selectObject("FSA.ARCPLC", "EffectiveReferencePrices", _fsaDataFilter);
            _currentCrop.EffectiveReferencePrices = _effectiveReferencePrices;
        }

        return _uniqueCropBaseAcres;
    }

    // --- --- ---

    const getARCCOPrices = async () => {
        if (!baseAcres?.length) {
            baseAcres = await loadBaseAcres();
        }
        const _uniqueCropBaseAcres = getUniqueBaseAcreCrops(baseAcres);

        for (const _currentCrop of _uniqueCropBaseAcres) {
            const _fsaDataFilter = {
                crop_year: _currentCrop?.crop_year,
                commodity_name: _currentCrop?.commodity_name
            };
            var _arcCoPrices = await ApiHelper.selectObject("FSA.ARCPLC", "ARCCOPrices", _fsaDataFilter);
            _currentCrop.ARCCOPrices = _arcCoPrices;
        }

        return _uniqueCropBaseAcres;
    }

    // --- --- ---

    const getARCCOYields = async () => {
        if (!baseAcres?.length) {
            baseAcres = await loadBaseAcres();
        }
        const _uniqueCropBaseAcres = getUniqueBaseAcreCrops(baseAcres);

        for (const _currentCrop of _uniqueCropBaseAcres) {
            const _fsaDataCountyFilter = {
                crop_year: _currentCrop?.crop_year,
                commodity_name: _currentCrop?.commodity_name,
                state_name: _currentCrop?.location_state_name,
                county_name: _currentCrop?.location_county_name
            };

            var _arcCoYields = await ApiHelper.selectObject("FSA.ARCPLC", "ARCCOYields", _fsaDataCountyFilter);
            _currentCrop.ARCCOYields = _arcCoYields;
        }

        return _uniqueCropBaseAcres;
    }


    // --- --- ---

    const getArcPlc = async () => {
        if (!baseAcres?.length) {
            baseAcres = await loadBaseAcres();
        }
        const _uniqueCropBaseAcres = getUniqueBaseAcreCrops(baseAcres);

        const _programElections = await loadProgramElections();

        const _arcPlcResponses_async = [];
        for (var currentBaseAcre of _uniqueCropBaseAcres) {
            const _arcPlc = await selectArcPlc(currentBaseAcre);
            _arcPlcResponses_async.push({
                baseAcres: currentBaseAcre,
                arcPlcResponse: _arcPlc,
            });
        }

        const _arcPlcResponses = (await Promise.all(_arcPlcResponses_async))
            .filter(_ap => _ap);

        const _totals = {
            arcPayment: 0,
            plcPayment: 0,
            base_acreage: 0,
            plc_yield: 0,
        };
        _arcPlcResponses.forEach(_currentArcPlc => {
            if (_currentArcPlc?.arcPlcResponse?.arcPayment) _totals.arcPayment += _currentArcPlc.arcPlcResponse.arcPayment
            if (_currentArcPlc?.arcPlcResponse?.plcPayment) _totals.plcPayment += _currentArcPlc.arcPlcResponse.plcPayment
            if (_currentArcPlc?.baseAcres) _totals.base_acreage += _currentArcPlc.baseAcres.base_acreage
            if (_currentArcPlc?.baseAcres) _totals.plc_yield += _currentArcPlc.baseAcres.plc_yield * _currentArcPlc.baseAcres.base_acreage
        });
        _totals.plc_yield = _totals.plc_yield / _.sumBy(_arcPlcResponses, arcplc => arcplc.baseAcres.base_acreage);

        const _crops = _.uniq(_arcPlcResponses.map(_arcplc => _arcplc?.baseAcres?.commodity_name))
            .map(_commodityName => ({ commodity_name: _commodityName }))
        _crops.forEach(_currentCrop => {
            const _cropArcPlcResponses = _arcPlcResponses.filter(_arcPlc =>
                _arcPlc?.baseAcres?.commodity_name == _currentCrop?.commodity_name
            );
            const _cropTotals = {
                arcPayment: 0,
                plcPayment: 0,
                base_acreage: 0,
                plc_yield: 0,
            };
            _cropArcPlcResponses.forEach(_currentArcPlc => {
                if (_currentArcPlc?.arcPlcResponse?.arcPayment) _cropTotals.arcPayment += _currentArcPlc.arcPlcResponse.arcPayment
                if (_currentArcPlc?.arcPlcResponse?.plcPayment) _cropTotals.plcPayment += _currentArcPlc.arcPlcResponse.plcPayment
                if (_currentArcPlc?.baseAcres) _cropTotals.base_acreage += _currentArcPlc.baseAcres.base_acreage
                if (_currentArcPlc?.baseAcres) _cropTotals.plc_yield += _currentArcPlc.baseAcres.plc_yield * _currentArcPlc.baseAcres.base_acreage
            });
            _cropTotals.plc_yield = _cropTotals.plc_yield / _.sumBy(_cropArcPlcResponses, arcplc => arcplc.baseAcres.base_acreage);

            _currentCrop.arcPayment = _cropTotals.arcPayment;
            _currentCrop.plcPayment = _cropTotals.plcPayment;
            _currentCrop.base_acreage = _cropTotals.base_acreage;
            _currentCrop.plc_yield = _cropTotals.plc_yield;
        });

        _programElections.forEach(_election => {
            const _arcPlcElections = _arcPlcResponses.filter(_arcPlc =>
                _arcPlc?.baseAcres?.commodity_name == _election?.commodity_name
                && _arcPlc?.baseAcres?.location_state_name == _election?.state_name
                && _arcPlc?.baseAcres?.location_county_name == _election?.county_name
            );

            _election.elections = _arcPlcElections;
            _election.totals = {
                arcPayment: 0,
                plcPayment: 0,
            };
            if (_arcPlcElections?.length) {
                if (_election.program_election == PROGRAM_NAME_ARCCO) {
                    _election.totals.arcPayment = _.sumBy(_arcPlcElections, e => e.arcPlcResponse?.arcPayment);
                } else if (_election.program_election == PROGRAM_NAME_PLC) {
                    _election.totals.plcPayment = _.sumBy(_arcPlcElections, e => e.arcPlcResponse?.plcPayment);
                }
            }
        })

        _totals.programElections = {
            arcPayment: _.sumBy(_programElections, pe => pe.totals?.arcPayment),
            plcPayment: _.sumBy(_programElections, pe => pe.totals?.plcPayment),
        }
        _totals.programElections.totalPayment = _totals.programElections.arcPayment + _totals.programElections.plcPayment;

        return {
            arcPlcResponses: _crops,
            programElections: _programElections,
            totals: _totals
        };
    }

    // --- --- ---

    const actions = {
        getArcPlc,

        getPLCPaymentRates,

        getARCCOPrices,
        getARCCOYields,

        getEffectiveReferencePrices
    };

    return {
        actions
    }
}