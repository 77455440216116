const _percentageFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    signDisplay: "exceptZero",
});
const _priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 0
});
const _priceFormatter2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2
});


const _priceFormatter_2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2
});
const _priceFormatter_4 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 4
});

export const formatPrice = (value, places) => {
    if (places == 2) {
        //return value?.toFixed(0);
        return _priceFormatter_2.format(value || 0)?.replace("$", "$ ");
    } else if (places == 4) {
        //return value?.toFixed(0);
        return _priceFormatter_4.format(value || 0)?.replace("$", "$ ");
    } else {
        //return value?.toFixed(0);
        return _priceFormatter.format(value || 0)?.replace("$", "$ ");
    }
}

export const formatPrice2 = (value) => {
    //return value?.toFixed(0);
    return _priceFormatter2.format(value || 0);
}

export const formatFixed1 = (value) => {
    const places = 1;
    value = ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);
    return value;
}

export const formatFixed2 = (value) => {
    const places = 2;
    value = ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);
    return value;
}

export const formatNumber = (value, places) => {
    // return ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);

    value = ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);
    const _numberFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: places || 0 });
    return _numberFormatter.format(value);
}

Math.round_ex = function (number, places) {
    //return +(Math.round(number + "e+" + places) + "e-" + places);
    return Number(number.toFixed(places || 0));
}

export const formatPercentage = (value, places) => {
    return formatNumber((value || 0) * 100, (places == null ? 2 : places)) + "%";
}

export const formatDate = (date) => {
    return (new Date(date)).toLocaleDateString('en-us');
}

// ---

export const PRACTICE_NAME_SHORT_IRRIGATED = "IRR";
export const PRACTICE_NAME_SHORT_NON_IRRIGATED = "NI";
export const PRACTICE_NAME_SHORT_NON_IRRIGATED_SKIP_ROW = "NI/Skip";

export const formatPracticeName = (value) => {
    if (value?.toLowerCase()?.includes("skip row")) {
        return PRACTICE_NAME_SHORT_NON_IRRIGATED_SKIP_ROW;
    } else if (value?.toLowerCase()?.includes("non-irrigated")) {
        return PRACTICE_NAME_SHORT_NON_IRRIGATED;
    } else if (value?.toLowerCase()?.includes("irrigated")) {
        return PRACTICE_NAME_SHORT_IRRIGATED;
    } else {
        return "";
    }
}