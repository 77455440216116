import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Alert, Box, CircularProgress, Divider, FormControl, Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FinancialIcon } from 'components/Icons/Icons'
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { ValueLabel } from 'mui-toolkit/ValueLabel'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import useFinanceReport from '../useFinanceReport'

export default function DebtScheduleWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(globalState.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const calculateContainersCrops = () => {
        return _.uniq(debtSchedule?.crops?.values?.map(v => v.container));
    }

    const calculateContainersEquipment = () => {
        return _.uniq(debtSchedule?.equipment?.values?.map(v => v.container));
    }

    const calculateContainersLivestock = () => {
        return _.uniq(debtSchedule?.livestock?.values?.map(v => v.container));
    }

    const calculateContainersRealEstate = () => {
        return _.uniq(debtSchedule?.real_estate?.values?.map(v => v.container));
    }

    const {
        isLoading,

        isLoadingDebtSchedule,
        debtSchedule,
        loadDebtSchedule,
    } = useFinanceReport({
        onError: handleError,
        currentYear,

        loadModules: [MODULE_FINANCIAL]
    })

    // --- --- ---

    const handleChangeState_LineItem = async (event, _currentDocumentDictionary) => {
        if (event?.target?.value != debtSchedule?.values.find(v => v.id == _currentDocumentDictionary?.id)?.value) {
            formStatusActions.setIsFormDirty(true, true);

            const _newValue = {
                ..._currentDocumentDictionary,
                id: _currentDocumentDictionary?.id || uuidv4(),
                value: event?.target?.value || 0
            }

            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                Document: {
                    id: _newValue?.document_id || uuidv4(),
                    values: [_newValue]
                }
            };

            await UsersApiHelper.updateFinancialDocument(debtSchedule, globalState);

            UsersApiHelper.updateDocumentDictionary(_uploadFormData, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadDebtSchedule();
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    const formatName = (value) => {
        if (value) {
            return value.replaceAll("_", " ")
        }
    }

    // --- --- ---

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    return (
        <>
            <WorkspaceHeader
                title='Debt Schedule'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        // path: '/financial/documents',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoading && <CircularProgress />}
                {formStatusComponent}
            </Stack>

            {debtSchedule?.values?.length == 0 && <>
                <Alert severity="warning">
                    No Debt Schedule form is found.
                    <Divider />
                    Please upload or enter the document and try again.
                </Alert>
                <br/>
            </>}

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container columnSpacing={6} rowSpacing={2}>
                    <Grid item xs={12}>
                        {calculateContainersCrops()?.map(container =>
                            <Box key={container} sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                                    {formatName(container)}
                                </Typography>

                                <Grid container spacing={2}>
                                    {Boolean(debtSchedule?.values?.length)
                                        && debtSchedule?.values?.filter(v => v.container == container).map((v, index) =>
                                            <Grid item key={index} xs={6} lg={3}>
                                                <FormControl fullWidth={true}>
                                                    <StatefulControlledTextField
                                                        label={formatName(v.item)}
                                                        name={v.item}
                                                        isRequired={false}
                                                        defaultValue={v.value}
                                                        variant="outlined"
                                                        onChange={(e) => handleChangeState_LineItem(e, v)}
                                                        InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                        maskType={MASK_NUMERIC}
                                                    />
                                                </FormControl>

                                                <br />
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} className='pageBreakAvoid'>
                        {calculateContainersEquipment()?.map(container =>
                            <Box key={container} sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                                    {formatName(container)}
                                </Typography>

                                <Grid container spacing={2}>
                                    {Boolean(debtSchedule?.values?.length)
                                        && debtSchedule?.values?.filter(v => v.container == container).map((v, index) =>
                                            <Grid item key={index} xs={6} lg={3}>
                                                <FormControl fullWidth={true}>
                                                    <StatefulControlledTextField
                                                        label={formatName(v.item)}
                                                        name={v.item}
                                                        isRequired={false}
                                                        defaultValue={v.value}
                                                        variant="outlined"
                                                        onChange={(e) => handleChangeState_LineItem(e, v)}
                                                        InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                        maskType={MASK_NUMERIC}
                                                    />
                                                </FormControl>

                                                <br />
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} className='pageBreakAvoid'>
                        {calculateContainersLivestock()?.map(container =>
                            <Box key={container} sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                                    {formatName(container)}
                                </Typography>

                                <Grid container spacing={2}>
                                    {Boolean(debtSchedule?.values?.length)
                                        && debtSchedule?.values?.filter(v => v.container == container).map((v, index) =>
                                            <Grid item key={index} xs={6} lg={3}>
                                                <FormControl fullWidth={true}>
                                                    <StatefulControlledTextField
                                                        label={formatName(v.item)}
                                                        name={v.item}
                                                        isRequired={false}
                                                        defaultValue={v.value}
                                                        variant="outlined"
                                                        onChange={(e) => handleChangeState_LineItem(e, v)}
                                                        InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                        maskType={MASK_NUMERIC}
                                                    />
                                                </FormControl>

                                                <br />
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} className='pageBreakAvoid'>
                        {calculateContainersRealEstate()?.map(container =>
                            <Box key={container} sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                                    {formatName(container)}
                                </Typography>

                                <Grid container spacing={2}>
                                    {Boolean(debtSchedule?.values?.length)
                                        && debtSchedule?.values?.filter(v => v.container == container).map((v, index) =>
                                            <Grid item key={index} xs={6} lg={3}>
                                                <FormControl fullWidth={true}>
                                                    <StatefulControlledTextField
                                                        label={formatName(v.item)}
                                                        name={v.item}
                                                        isRequired={false}
                                                        defaultValue={v.value}
                                                        variant="outlined"
                                                        onChange={(e) => handleChangeState_LineItem(e, v)}
                                                        InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                        maskType={MASK_NUMERIC}
                                                    />
                                                </FormControl>

                                                <br />
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
