import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useContext, useState } from 'react'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { Divider } from '@aws-amplify/ui-react'


const MOCK_DATA = [

    // Corn:
    {
        report_summary: {
            "commodity_name": "Corn",
            "reported_acres_total": 2020,
            "average_yield": 230.00,
            "production_quantity": 464600,//calculated: reported_acres_total * average_yield

            "insured_quantity": 431775,
            "insured_percentage_sold": 0.9016,//calculated: insured_quantity / production_quantity

            "hedged_quantity": 389296,
            "hedged_percentage_production": 0.8379,//calculated: hedged_quantity / production_quantity

            "basis": 0.0000
        },

        markets: {
            "symbol": "ZCU24",// Corn: See: https://www.barchart.com/futures/quotes/ZC*1/profile
            "price": 3.7775,

            "month_name": "Sep",
            "year": 2024,
        },

        budget: {
            "projected_price": 5.9942,
            "revenue": 2784928.49,
            "revenue_per_acre": 1378.68,//calculated: revenue / reported_acres_total
        },

        position_summary: [
            {
                "id": "0141ca97-3a24-4e4e-a9c7-55fda1787349",
                "commodity_name": "Corn",
                "category": "Cash",
                "amount": 294296.10,
                "percentage_of_production": 63.34,//calculated: amount / production_quantity
                "price": 6.54300,
                "revenue": 1925580.51,//calculated: amount * price
                "revenue_per_acre": 953.28//calculated: revenue / reported_acres_total
            },
            {
                "id": "833d92fb-5499-4f81-b0e7-0a888dea55d0",
                "commodity_name": "Corn",
                "category": "Basis",
                "amount": 0.00,
                "percentage_of_production": 0.00,
                "price": 0.00000,
                "revenue": 0,//calculated: amount * price
                "revenue_per_acre": 0//calculated: revenue / reported_acres_total
            },
            {
                "id": "5bcac169-2152-460c-bf5c-fff98d7b04cb",
                "commodity_name": "Corn",
                "category": "HTA",
                "amount": 95000.00,
                "percentage_of_production": 20.45,
                "price": 6.08790,
                "gross_revenue": 578350.00
            },

            // These values will already be calculated by the biz layer:
            // Calculated summary values by the business layer:
            {
                "id": null,//calculated
                "commodity_name": "Corn",
                "category": "Sold",
                "amount": 389296.10,
                "percentage_of_production": 83.79,
                "price": 6.43190,
                "revenue": 2503930.51,//calculated: amount * price
                "revenue_per_acre": 111 // Dummy mock data //calculated: revenue / reported_acres_total
            },
            {
                "id": null,//calculated
                "commodity_name": "Corn",
                "category": "Unsold",
                "amount": 75303.90,
                "percentage_of_production": 16.21,
                "price": 3.77750,
                "revenue": 2503930.51,//calculated: amount * price
                "revenue_per_acre": 111 // Dummy mock data //calculated: revenue / reported_acres_total
            }
        ]
    },





    // Soybean:
    {
        report_summary: {
            "commodity_name": "Soybean",
            "reported_acres_total": 2020,
            "average_yield": 13.00,
            "production_quantity": 464600,//calculated: reported_acres_total * average_yield

            "insured_quantity": 111,
            "insured_percentage_sold": 0.9016,//calculated: insured_quantity / production_quantity

            "hedged_quantity": 389296,
            "hedged_percentage_production": 0.8379,//calculated: hedged_quantity / production_quantity

            "basis": 0.0000
        },

        markets: {
            "symbol": "ZCU24",// Corn: See: https://www.barchart.com/futures/quotes/ZC*1/profile
            "price": 13.7775,

            "month_name": "Sep",
            "year": 2024,
        },

        budget: {
            "projected_price": 13.9942,
            "revenue": 2784928.49,
            "revenue_per_acre": 1378.68,//calculated: revenue / reported_acres_total
        },

        position_summary: [
            {
                "id": "0141ca97-3a24-4e4e-a9c7-55fda1787349",
                "commodity_name": "Soybean",
                "category": "Cash",
                "amount": 294296.10,
                "percentage_of_production": 63.34,//calculated: amount / production_quantity
                "price": 6.54300,
                "revenue": 1925580.51,//calculated: amount * price
                "revenue_per_acre": 953.28//calculated: revenue / reported_acres_total
            },
            {
                "id": "833d92fb-5499-4f81-b0e7-0a888dea55d0",
                "commodity_name": "Soybean",
                "category": "Basis",
                "amount": 0.00,
                "percentage_of_production": 0.00,
                "price": 0.00000,
                "revenue": 0,//calculated: amount * price
                "revenue_per_acre": 0//calculated: revenue / reported_acres_total
            },
            {
                "id": "5bcac169-2152-460c-bf5c-fff98d7b04cb",
                "commodity_name": "Soybean",
                "category": "HTA",
                "amount": 95000.00,
                "percentage_of_production": 20.45,
                "price": 6.08790,
                "gross_revenue": 578350.00
            },

            // These values will already be calculated by the biz layer:
            // Calculated summary values by the business layer:
            {
                "id": null,//calculated
                "commodity_name": "Soybean",
                "category": "Sold",
                "amount": 389296.10,
                "percentage_of_production": 83.79,
                "price": 6.43190,
                "revenue": 2503930.51,//calculated: amount * price
                "revenue_per_acre": 111 // Dummy mock data //calculated: revenue / reported_acres_total
            },
            {
                "id": null,//calculated
                "commodity_name": "Soybean",
                "category": "Unsold",
                "amount": 75303.90,
                "percentage_of_production": 16.21,
                "price": 3.77750,
                "revenue": 2503930.51,//calculated: amount * price
                "revenue_per_acre": 111 // Dummy mock data //calculated: revenue / reported_acres_total
            }
        ]
    },

];

export default function PositionSummaryWorkspace() {

    const { globalState } = useContext(AppContext);

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(true);

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    return (
        <>
            <WorkspaceHeader
                title='Postion Summary'
                breadCrumbs={[{
                    title: 'Marketing',
                    // path: '/vault',
                    icon: <FarmVaultIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12} lg={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                        <Typography variant='h1'>
                            Year
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)}>
                                        {currentYear} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {getBudgetYears().map((year, index) =>
                                            <MenuItem key={index} onClick={() => {
                                                setCurrentYear(year);
                                                popupState.close();
                                            }}>
                                                {year}
                                            </MenuItem>)}
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                        {/* {isLoadingCurrent && <CircularProgress />} */}
                    </Stack>
                </Grid>
            </Grid>



        </>
    )
}