import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from "react";
import { SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_OTHER, SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPES } from "./schedule_financial_statement_accounts_liquid";
import { AppContext } from "App";
import { USER_ROLE_ADMIN } from "@datanac/datanac-api-toolkit/dist/api-helper";

// ---

export const FinancialStatementSchedule_Accounts_Edit = ({
    onChange,
    onClose,
    account,
    is_lender
    /*
     {
            "year": 2024,
            "financial_institution_name": "FNB",
            "account_name": "My Account",
            "account_type": "Savings",
            "value": 100000,
            "adjusted_value": 100000,
            "prior_value": 100000
        }
    */
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, setState] = useState({
        account_type: SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_OTHER,
        is_active: true,
    });
    const { enqueueSnackbar } = useSnackbar();

    // ---

    const onSubmit = (e) => {
        onChange && onChange(state);

        // if (account == null || Object.keys(account).length === 0) {
        //     enqueueSnackbar("Account added", { preventDuplicate: true, variant: 'info' });
        // } else {
        //     enqueueSnackbar("Account updated", { preventDuplicate: true, variant: 'info' });
        // }

        onClose && onClose();
    }

    useEffect(() => {
        setState(account);
    }, [account]);

    // ---

    const handleChangeStateSelect = (event) => {
        formStatusActions.setIsFormDirty(true, false);

        if (state[event.target.name] != event.target.value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = event.target.value;
            setState(_newState);
        }
    }

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    // ---

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    return (<>
        <DialogTitle>
            {state?.financial_institution_name}
        </DialogTitle>

        <DialogContent>
            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Name"
                                name="account_name"
                                isRequired={true}
                                defaultValue={state?.account_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Instution"
                                name="financial_institution_name"
                                isRequired={true}
                                defaultValue={state?.financial_institution_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <InputLabel id='account_typeLabel'>Type</InputLabel>
                            <Select
                                labelId='account_typeLabel'
                                value={state?.account_type || ''}
                                name={"account_type"}
                                onChange={handleChangeStateSelect}
                                label={'Type'}
                                disabled={false}
                            >
                                {SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPES?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {is_lender == true && <Grid item xs={12} md={6}>
                        &nbsp;
                    </Grid>}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Value $"
                                name="value"
                                isRequired={true}
                                defaultValue={state?.value || 0}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                                disabled={is_lender == true && globalState?.user_role != USER_ROLE_ADMIN}
                            />
                        </FormControl>
                    </Grid>
                    {is_lender == true || globalState?.user_role == USER_ROLE_ADMIN &&
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth={true}>
                                <StatefulControlledTextField
                                    label="Adjusted Value $"
                                    name="adjusted_value"
                                    isRequired={true}
                                    defaultValue={state?.adjusted_value || 0}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    maskType={MASK_NUMERIC}

                                />
                            </FormControl>
                        </Grid>}
                    {is_lender == true && <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Prior Value $"
                                name="prior_value"
                                isRequired={true}
                                defaultValue={state?.prior_value || 0}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>}
                </Grid>
            </Box>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>
    </>);
}