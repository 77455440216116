import { calculate_cecl_from_stresstest } from '@datanac/datanac-api-toolkit/dist/insurance/stress-test/cecl'
import { calculateStressTest } from '@datanac/datanac-api-toolkit/dist/insurance/stress-test/crop/crop_stress_test'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { AppContext } from 'App'
import { ApiHelper, RmaApiHelper, UsersApiHelper } from 'api/ApiHelper'
import { BUDGET_STATUS_ACTIVE } from 'components/Budget/helpers'
import { getUniqueCounties } from 'components/Vault/Farm/helpers'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { InsuranceService } from './InsuranceService'
import { STRESS_TEST_FIELD_NET_PROFIT_INSURED } from './helpers'


export default function useInsurance({
    insurance_scenario_id,
    state_name, county_name,
    commodity_name, type_name, practice_name,

    crop_year
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // --- --- ---

    const handleError = (message) => {
        enqueueSnackbar(message?.detail || message,
            { preventDuplicate: true, variant: 'error' });
        console.warn(JSON.stringify(message));
        console.trace();
    }

    const [insurancePlanTimestamp, setInsurancePlanTimestamp] = useState(uuidv4())
    const [insurancePlans, setInsurancePlans] = useState([]);

    // --- --- ---

    const [insuranceScenarios, setInsuranceScenarios] = useState(null);
    const [isLoadingInsuranceScenarios, setIsLoadingInsuranceScenarios] = useState(true);
    const loadInsuranceScenario = async () => {
        try {
            setIsLoadingInsuranceScenarios(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year: crop_year,
                is_active: true
            };
            if (insurance_scenario_id) {
                producerFilter.id = insurance_scenario_id;
            }
            const _insuranceScenarios = await UsersApiHelper.users_selectObject("InsuranceScenario", producerFilter, globalState);

            setInsuranceScenarios(_insuranceScenarios);

            setIsLoadingInsuranceScenarios(false);
        } catch (err) {
            setIsLoadingInsuranceScenarios(false);
            setInsuranceScenarios([]);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadInsuranceScenario();
        }
    }, [globalState?.producer_token]);

    // --- --- ---

    const [budgetCountyPractices, setBudgetCountyPractices] = useState(null);
    const [isLoadingBudgetCountyPractices, setIsLoadingBudgetCountyPractices] = useState(true);
    const loadBudgetCountyPractices = async () => {
        try {
            const _budgetFilter = {
                // state_name, county_name,
                // commodity_name, type_name, practice_name,
                year: crop_year,
                status: BUDGET_STATUS_ACTIVE,
                is_active: true,
            }
            if (state_name) { _budgetFilter.state_name = state_name; }
            if (county_name) { _budgetFilter.county_name = county_name; }
            if (commodity_name) { _budgetFilter.commodity_name = commodity_name; }
            if (type_name) { _budgetFilter.type_name = type_name; }
            if (practice_name) { _budgetFilter.practice_name = practice_name; }

            const _budgets = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, globalState);

            setBudgetCountyPractices(_budgets);

            // const _cropFilter = {
            //     crop_year: crop_year,
            //     location_state_name: state_name, location_county_name: county_name,
            //     commodity_name, type_name, practice_name,
            //     is_active: true,
            // };
            // const _crops = await UsersApiHelper.selectProducerFarmFieldCrops(_cropFilter, globalState);
            // const _cropsCountyPractice = getUniqueCounties(_crops);

            // if (_budgets?.length) {
            //     for (const _activeBudgetCountyPractice of _budgets) {
            //         _activeBudgetCountyPractice.reinsurance_year = _activeBudgetCountyPractice?.year;

            //         _activeBudgetCountyPractice.production_cost_per_acre = _activeBudgetCountyPractice?.reported_acreage > 0 &&
            //             _activeBudgetCountyPractice?.production_cost / _activeBudgetCountyPractice?.reported_acreage;

            //         // if (_cropsCountyPractice?.length) {
            //         //     const _currentCropCountyPractice = _cropsCountyPractice[0];

            //         //     _activeBudgetCountyPractice.approved_yield = _currentCropCountyPractice.approved_yield_weighted;
            //         //     _activeBudgetCountyPractice.rate_yield = _currentCropCountyPractice.rate_yield_weighted;
            //         //     _activeBudgetCountyPractice.adjusted_yield = _currentCropCountyPractice.adjusted_yield_weighted;
            //         //     _activeBudgetCountyPractice.share_percent = _currentCropCountyPractice.share_percent_weighted;
            //         // }
            //     }

            //     setBudgetCountyPractices(_activeBudgetCountyPractice);
            // }

            setIsLoadingBudgetCountyPractices(false);
        } catch (err) {
            setIsLoadingBudgetCountyPractices(false);
            setBudgetCountyPractices(null);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        // if (insuranceScenarios?.length) {
        loadBudgetCountyPractices();
        // }
    }, [globalState?.producer_token])

    // --- --- ---

    const [producerFarmFieldCrops, setProducerFarmFieldCrops] = useState(null);
    const loadProducerFarmFieldCrops = async () => {
        const _cropFilter = {
            crop_year: crop_year,
            // location_state_name: state_name,
            // location_county_name: county_name,
            // commodity_name, type_name, practice_name,
            is_active: true,
        };
        if (state_name) { _cropFilter.location_state_name = state_name; }
        if (county_name) { _cropFilter.location_county_name = county_name; }
        if (commodity_name) { _cropFilter.commodity_name = commodity_name; }
        if (type_name) { _cropFilter.type_name = type_name; }
        if (practice_name) { _cropFilter.practice_name = practice_name; }

        const _crops = await UsersApiHelper.selectProducerFarmFieldCrops(_cropFilter, globalState);

        if (_crops?.length) {
            setProducerFarmFieldCrops(_crops);
        }
    }

    useEffect(() => {
        if (budgetCountyPractices?.length) {
            loadProducerFarmFieldCrops();
        }
    }, [globalState?.producer_token, budgetCountyPractices])

    // --- --- ---

    const [countyData, setCountyData] = useState(null);
    const loadCountyData = async () => {
        let _currentCountyFilter = {
            reinsurance_year: crop_year,
            // state_name,county_name,
            // commodity_name,type_name,practice_name
        };
        if (state_name) { _currentCountyFilter.location_state_name = state_name; }
        if (county_name) { _currentCountyFilter.location_county_name = county_name; }
        if (commodity_name) { _currentCountyFilter.commodity_name = commodity_name; }
        if (type_name) { _currentCountyFilter.type_name = type_name; }
        if (practice_name) { _currentCountyFilter.practice_name = practice_name; }

        // const currentCounty = await _loadCountyData_Intern(_currentCountyFilter);
        // setCountyData(currentCounty);
    }

    const _loadCountyData_Intern = async (_currentCountyFilter) => {
        const currentPrices = await RmaApiHelper.selectPrices(_currentCountyFilter);
        const currentCountyData = await RmaApiHelper.selectCountyData(_currentCountyFilter);
        const currentCounty = {
            ...(currentPrices && currentPrices.length && currentPrices[0]),
            ..._currentCountyFilter,
            reinsurance_year: crop_year,
            expected_index_value: currentCountyData?.length && currentCountyData[0].expected_index_value
        }
        return currentCounty;
    }

    useEffect(() => {
        // WARN: if the full params are not specified, we cannot load county data.
        // It will be loaded on demand with _loadCountyData_Intern.
        if (state_name && county_name && commodity_name && type_name && practice_name) {
            loadCountyData();
        }
    }, [globalState?.producer_token, state_name, county_name, commodity_name, type_name, practice_name]);

    // --- --- ---

    const [isLoadingInsurancePlans, setIsLoadingInsurancePlans] = useState(true);
    const loadInsurancePlans = async () => {
        try {
            setIsLoadingInsurancePlans(true);
            setInsurancePlanTimestamp(uuidv4());

            const _InsuranceService = new InsuranceService({
                // insuranceScenarios: insuranceScenarios.filter(isc => isc),
                // budgetCountyPractices: budgetCountyPractices.filter(cp => cp),
                crop_year: crop_year,
                apiConfig: globalState
            });
            const _insuranceScenarioPopulated = await _InsuranceService.actions.loadInsuranceScenario();

            if (_insuranceScenarioPopulated?.length
                && _insuranceScenarioPopulated[0].budgetCountyPractices?.length
                && _insuranceScenarioPopulated[0].budgetCountyPractices[0]?.insurancePlans?.length
            ) {
                const _allInsurancePlans = [];
                for (const _currentBudgetCountyPractice of _insuranceScenarioPopulated[0].budgetCountyPractices) {
                    _allInsurancePlans.push(..._currentBudgetCountyPractice.insurancePlans);
                }

                setInsurancePlans(_allInsurancePlans);
                setInsurancePlanTimestamp(uuidv4());

                _InsuranceService.actions.calculatePremiums(_insuranceScenarioPopulated[0])
                    .then((calculatePremiumsResponseInsuranceScenario) => {
                        const _allCalculatedInsurancePlans = [];
                        for (const _currentBudgetCountyPractice of calculatePremiumsResponseInsuranceScenario?.budgetCountyPractices) {
                            _allCalculatedInsurancePlans.push(..._currentBudgetCountyPractice.insurancePlans);
                        }

                        setInsurancePlans(_allCalculatedInsurancePlans);
                        setInsurancePlanTimestamp(uuidv4());
                        setIsLoadingInsurancePlans(false);
                    })
            } else {
                setInsurancePlans([]);
                setInsurancePlanTimestamp(uuidv4());
                setIsLoadingInsurancePlans(false);
            }
        } catch (err) {
            setIsLoadingInsurancePlans(false);
            setInsurancePlans(null);
            setInsurancePlanTimestamp(uuidv4());
            console.warn(err);
            enqueueSnackbar(err.detail || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && insuranceScenarios?.length && budgetCountyPractices?.length) {
            loadInsurancePlans();
        } else {
            setInsurancePlans([]);
            setInsurancePlanTimestamp(uuidv4());
        }
    }, [globalState?.producer_token, budgetCountyPractices, insuranceScenarios]);

    // --- --- ---

    const [stressTestDisplayField, setStressTestDisplayField] = useState(STRESS_TEST_FIELD_NET_PROFIT_INSURED);
    const [isStressTestOptionsDialogOpen, setIsStressTestOptionsDialogOpen] = useState(false);

    const [stressTestParameters, setStressTestParameters] = useState(null);
    const [stressTestResults, setStressTestResults] = useState(null);
    const [isLoadingStressTestResults, setIsLoadingStressTestResults] = useState(true);
    const loadStressTestResults = async () => {
        try {
            let _currentCountyFilter = {
                reinsurance_year: crop_year,
                state_name,
                county_name,
                commodity_name,
                type_name,
                practice_name
            };

            const _currentStressTestResults = [];
            // Loop in a way that supports await budgetCountyPractice
            for (const _currentBudgetCountyPractice of budgetCountyPractices) {
                _currentCountyFilter.state_name = _currentBudgetCountyPractice.state_name;
                _currentCountyFilter.county_name = _currentBudgetCountyPractice.county_name;
                _currentCountyFilter.commodity_name = _currentBudgetCountyPractice.commodity_name;
                _currentCountyFilter.type_name = _currentBudgetCountyPractice.type_name;
                _currentCountyFilter.practice_name = _currentBudgetCountyPractice.practice_name;
                const _currentCountyData = await _loadCountyData_Intern(_currentCountyFilter);

                const props = {
                    farmParameters: _currentBudgetCountyPractice,
                    countyParameters: [_currentCountyData],
                    insurancePlanParameters: insurancePlans,
                    testParameters: stressTestParameters,
                    apiHelper: ApiHelper
                };

                // console.log("stressTest props", props);
                const _currentStressTestResults_Batch = await calculateStressTest(props);
                if (_currentStressTestResults_Batch) {
                    _currentStressTestResults_Batch.cecl = calculate_cecl_from_stresstest({
                        stressTestResults: _currentStressTestResults.results
                    });

                    _currentStressTestResults.push(_currentStressTestResults_Batch);

                    // console.log("stressTest results", _currentStressTestResults);
                    // console.log("cecl", _currentStressTestResults?.cecl);
                }
            }

            setStressTestResults(_currentStressTestResults);
            setIsLoadingStressTestResults(false);
        } catch (err) {
            setIsLoadingStressTestResults(false);
            setStressTestResults([]);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && budgetCountyPractices?.length /*&& countyData?.length*/ && insurancePlans?.length && stressTestParameters != null) {
            loadStressTestResults();
        }
    }, [globalState?.producer_token, budgetCountyPractices, /*countyData,*/ isLoadingInsurancePlans, insurancePlanTimestamp, stressTestParameters]);

    // ---

    const loadStressTestParameters = async () => {
        const filter = {
            producer_token: globalState.producer_token,
            // insurance_scenario_id,
            // state_name,
            // county_name,
            // commodity_name,
            // type_name,
            // practice_name,
            is_active: true
        };
        if (insurance_scenario_id) filter.insurance_scenario_id = insurance_scenario_id;
        if (state_name) { filter.state_name = state_name; }
        if (county_name) { filter.county_name = county_name; }
        if (commodity_name) { filter.commodity_name = commodity_name; }
        if (type_name) { filter.type_name = type_name; }
        if (practice_name) { filter.practice_name = practice_name; }

        const _stressTestParameters = await UsersApiHelper.users_selectObject("InsuranceStressTestParameters", filter, globalState);
        setStressTestParameters(_stressTestParameters);
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadStressTestParameters();
        }
    }, [globalState?.producer_token]);

    // --- --- ---

    const [isOptimizeDialogOpen, setIsOptimizeDialogOpen] = useState(false);

    const handleInsurancePlanCardChange = async (_insurancePlan) => {
        if (_insurancePlan) {
            _insurancePlan.insurance_scenario_id = insurance_scenario_id;
            await UsersApiHelper.updateInsurancePlans({ InsurancePlans: [_insurancePlan] }, globalState);
            loadInsurancePlans();
        }
    }

    const handleOptimizerOnChange = async (_insurancePlans) => {
        handleAddPlanSelected(_insurancePlans);
    }

    const handleAddPlanSelected = async (_newInsurancePlans) => {
        if (insurancePlans?.length) {
            insurancePlans?.forEach(p => {
                p.is_active = false;
            })
        }

        _newInsurancePlans?.forEach(p => {
            p.id = p.id || uuidv4();
            p.reinsurance_year = crop_year || getCurrentReinsuranceYear();
            p.insurance_scenario_id = insurance_scenario_id;

            p.state_name = budgetCountyPractice?.state_name;
            p.county_name = budgetCountyPractice?.county_name;
            p.commodity_name = budgetCountyPractice?.commodity_name;
            p.type_name = budgetCountyPractice?.type_name;
            p.practice_name = budgetCountyPractice?.practice_name;

            p.is_active = true;
        });

        try {
            await UsersApiHelper.updateInsurancePlans({
                InsurancePlans: [
                    ...insurancePlans,
                    ..._newInsurancePlans
                ]
            }, globalState);
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }

        loadInsurancePlans();
    }

    // --- --- ---

    const [isAddInsuranceDialogOpen, setIsAddInsuranceDialogOpen] = useState(false);

    // --- --- ---

    // const calculateBreakeven = (_loanAmount) => {
    //     return (_loanAmount / budgetCountyPractice?.reported_acreage) / budgetCountyPractice?.yield;
    // }

    return {
        budgetCountyPractices,
        isLoadingBudgetCountyPractices,

        producerFarmFieldCrops,
        // countyData,

        insuranceScenarios,
        isLoadingInsuranceScenarios,
        insurancePlans,
        isLoadingInsurancePlans,

        stressTestParameters,
        stressTestResults,
        isLoadingStressTestResults,
        // stressTestDisplayField,
        // isStressTestOptionsDialogOpen,

        // handleStressTestMenuClick,
        // handleStressTestOptionsDialogOpenClick,
        // handleStressTestParametersChange,

        // handleInsurancePlanCardChange,
        // handleOptimizerOnChange,
        // handleAddPlanSelected,
        // calculateBreakeven,
    }
}
