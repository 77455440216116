import { ChatBubble } from "@mui/icons-material";
import { Box, CircularProgress, Grid, IconButton, Popper } from "@mui/material";
import { ChatPopupHelper, SYSTEM_PROMPT_ACCOUNT_PARSER } from "components/Financial/schedule_financial_statement/ChatPopupHelper";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

export const ChatAssistantButton = ({
    systemPrompt,
    onChangeTranscript,
    onChangeObject
}) => {
    const _anchorPopupEl = useRef(null);
    const [anchorTemplateMenuEl, setAnchorTemplateMenuEl] = useState(null);

    // ---

    const {
        listening,
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const { enqueueSnackbar } = useSnackbar();

    if (!browserSupportsSpeechRecognition) {
        alert("Browser doesn't support speech recognition");
    }

    useEffect(() => {
        if (!listening) {
            onChangeTranscript && onChangeTranscript();
        }
    }, [transcript, listening])

    const handleStartRecording = async () => {
        resetTranscript();
        // https://www.npmjs.com/package/react-speech-recognition#basic-example
        SpeechRecognition.startListening({ continuous: true });
    }

    const handleStopRecording = async () => {
        // https://www.npmjs.com/package/react-speech-recognition#basic-example
        SpeechRecognition.stopListening();
    }

    const handleChatCompletion = async (_account) => {
        resetTranscript();

        if (_account) {
            // Test _account that it contains valid Array
            if (Array.isArray(_account)) {
                onChangeObject && onChangeObject(_account[0]);
                // enqueueSnackbar("Account added", { preventDuplicate: true, variant: 'info' });
            }
        }
    }

    // ---

    return (<>
        <IconButton ref={_anchorPopupEl} className="chatButton" sx={{ color: "blueviolet" }}
            onClick={() => {
                if (listening) {
                    handleStopRecording();
                } else {
                    handleStartRecording();
                }
            }}>
            {listening ? <CircularProgress size="1rem" /> : <ChatBubble />}
        </IconButton>
        <div ></div>
        <Popper open={Boolean(!listening && transcript)} anchorEl={_anchorPopupEl?.current}>
            <Box sx={{ p: 1 }}>
                <ChatPopupHelper onChatCompletion={handleChatCompletion} userMessage={transcript}
                    systemPrompt={systemPrompt} />
            </Box>
        </Popper>
    </>)
}