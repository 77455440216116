import _ from "lodash";

export const CROP_YEAR_POTENTIAL = "potential";

export function getUniqueCropPractices(producerFarmCrop) {
    // Get unique commodity_name, practice_name
    const _producerFarmCropWithGroupKey = [...producerFarmCrop];
    _producerFarmCropWithGroupKey.forEach(current => {
        current._GROUP_KEY = JSON.stringify({
            commodity_name: current.commodity_name,
            // type_name: current.type_name,
            practice_name: current.practice_name
        });
    });

    const _cropTypePracticeList = _(_producerFarmCropWithGroupKey)
        .groupBy("_GROUP_KEY")
        .map((currentGroup, id) => ({
            // Deserialize GROUP_KEY id object props:
            ...JSON.parse(id)
        }))
        .value();

    return _cropTypePracticeList;
}

export function getUniqueCounties(producerFarmCrop) {
    const _producerFarmCropWithGroupKey = [...producerFarmCrop];
    _producerFarmCropWithGroupKey.forEach(current => {
        current.approved_yield_weight = (current.approved_yield || null) * current.reported_acreage;
        current.rate_yield_weight = (current.rate_yield || null) * current.reported_acreage;
        current.adjusted_yield_weight = (current.adjusted_yield || null) * current.reported_acreage;
        current.share_percent_weight = (current.share_percent || null) * current.reported_acreage;
        current.production_cost_per_acre_weight = (current.production_cost_per_acre * current.reported_acreage || null);

        current._GROUP_KEY = JSON.stringify({
            location_state_name: current.location_state_name,
            location_county_name: current.location_county_name
        });
    });

    const _cropTypePracticeCountyList = _(_producerFarmCropWithGroupKey)
        .groupBy("_GROUP_KEY")
        .map((currentGroup, id) => ({
            // Deserialize GROUP_KEY id object props:
            ...JSON.parse(id),
            // Aggregates:
            reported_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                )?.toFixed(2)),
            base_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.base_acreage) || 0)
                )?.toFixed(2)),
            production_cost_per_acre:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.production_cost_per_acre_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.production_cost_per_acre_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,

            approved_yield_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.approved_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.approved_yield_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,

            rate_yield_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.rate_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.rate_yield_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,

            adjusted_yield_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.adjusted_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.adjusted_yield_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,

            share_percent_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.share_percent_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.share_percent_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,

            unit_count: currentGroup.length,
            // Child elements:
            producerFarmCrops: currentGroup.map(({ _GROUP_KEY, approved_yield_weight, ...pfcProps }) => pfcProps)
        }))
        .value();

    const _sorted = _cropTypePracticeCountyList.sort((a, b) =>
        a?.location_state_name?.localeCompare(b.location_state_name)
        || a?.location_county_name?.localeCompare(b.location_county_name)
    )

    return _sorted;
}

export function getUniqueProducerFarmCrops(producerFarmCrop) {
    if (!producerFarmCrop) return [];

    const _producerFarmCropWithGroupKey = [...producerFarmCrop];
    _producerFarmCropWithGroupKey
        .forEach(current => {
            current.approved_yield_weight = (current.approved_yield || null) * current.reported_acreage;
            current.production_cost_per_acre_weight = (current.production_cost_per_acre * current.reported_acreage || null);

            current._GROUP_KEY = JSON.stringify({
                location_state_name: current.location_state_name,
                location_county_name: current.location_county_name,
                fsa_farm_number: current.fsa_farm_number,

                commodity_name: current.commodity_name,
                type_name: current.type_name,
                practice_name: current.practice_name,
            });
        });

    const _cropTypePracticeCountyList = _(_producerFarmCropWithGroupKey)
        .filter(current =>
            current.fsa_farm_number
            && current.commodity_name
            && current.type_name
            && current.practice_name
        )
        .groupBy("_GROUP_KEY")
        .map((currentGroup, id) => ({
            // Deserialize GROUP_KEY id object props:
            ...JSON.parse(id),
            farm_name: currentGroup[0]?.farm_name,
            // Aggregates:
            reported_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                )?.toFixed(2)),
            base_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.base_acreage) || 0)
                )?.toFixed(2)),
            production_cost_per_acre:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.production_cost_per_acre_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.production_cost_per_acre_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,
            approved_yield_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.approved_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.approved_yield_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,
            unit_count: currentGroup.length,
            // Child elements:
            producerFarmCrops: currentGroup.map(({ _GROUP_KEY, approved_yield_weight, ...pfcProps }) => pfcProps)
        }))
        .value();

    const _sorted = _cropTypePracticeCountyList.sort((a, b) =>
        a?.location_state_name?.localeCompare(b.location_state_name)
        || a?.location_county_name?.localeCompare(b.location_county_name)

        || a?.fsa_farm_number?.localeCompare(b.fsa_farm_number)
    )

    return _sorted;
}

export function getUniqueFields(producerFarmCrop) {
    const _producerFarmCropWithGroupKey = [...producerFarmCrop];
    _producerFarmCropWithGroupKey
        .forEach(current => {
            current.approved_yield_weight = (current.approved_yield || null) * current.reported_acreage;
            current.production_cost_per_acre_weight = (current.production_cost_per_acre * current.reported_acreage || null);

            current._GROUP_KEY = JSON.stringify({
                location_state_name: current.location_state_name,
                location_county_name: current.location_county_name,
                fsa_farm_number: current.fsa_farm_number,
                fsa_tract_number: current.fsa_tract_number,
                fsa_field_number: current.fsa_field_number
            });
        });

    const _cropTypePracticeCountyList = _(_producerFarmCropWithGroupKey)
        .filter(current =>
            current.fsa_farm_number
            && current.fsa_tract_number
            && current.fsa_field_number
        )
        .groupBy("_GROUP_KEY")
        .map((currentGroup, id) => ({
            // Deserialize GROUP_KEY id object props:
            ...JSON.parse(id),
            // Aggregates:
            reported_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                )?.toFixed(2)),
            base_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.base_acreage) || 0)
                )?.toFixed(2)),
            production_cost_per_acre:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.production_cost_per_acre_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.production_cost_per_acre_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,
            approved_yield_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.approved_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.approved_yield_weight) && Number(item.reported_acreage) ? Number(item.reported_acreage) : 0))
                )?.toFixed(2)) || null,
            unit_count: currentGroup.length,
            // Child elements:
            producerFarmCrops: currentGroup.map(({ _GROUP_KEY, approved_yield_weight, ...pfcProps }) => pfcProps)
        }))
        .value();

    const _sorted = _cropTypePracticeCountyList.sort((a, b) =>
        a?.location_state_name?.localeCompare(b.location_state_name)
        || a?.location_county_name?.localeCompare(b.location_county_name)

        || a?.fsa_farm_number?.localeCompare(b.fsa_farm_number)
        || a?.fsa_tract_number?.localeCompare(b.fsa_tract_number)
        || a?.fsa_field_number?.localeCompare(b.fsa_field_number)
    )

    return _sorted;
}

export function getUniqueBaseAcreCrops(baseAcres) {
    const _baseAcresWithGroupKey = [...baseAcres];
    _baseAcresWithGroupKey.forEach(current => {
        current.plc_yield_weight = (current.plc_yield || null) * current.base_acreage;
        current.share_percent_weight = (current.share_percent || null) * current.base_acreage;

        current._GROUP_KEY = JSON.stringify({
            crop_year: current.crop_year,
            location_state_name: current.location_state_name,
            location_county_name: current.location_county_name,
            commodity_name: current.commodity_name,
        });
    });

    const _cropTypePracticeCountyList = _(_baseAcresWithGroupKey)
        .groupBy("_GROUP_KEY")
        .map((currentGroup, id) => ({
            // Deserialize GROUP_KEY id object props:
            ...JSON.parse(id),

            // Aggregates:
            base_acreage:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.base_acreage) || 0)
                )?.toFixed(2)),

            plc_yield_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.plc_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.plc_yield_weight) && Number(item.base_acreage) ? Number(item.base_acreage) : 0))
                )?.toFixed(2)) || null,
            plc_yield:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.plc_yield_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.plc_yield_weight) && Number(item.base_acreage) ? Number(item.base_acreage) : 0))
                )?.toFixed(2)) || null,

            share_percent_weighted:
                _.toNumber(_.toNumber(
                    (_.sumBy(currentGroup, item => Number(item.share_percent_weight) || 0)
                        / _.sumBy(currentGroup, item => Number(item.share_percent_weight) && Number(item.base_acreage) ? Number(item.base_acreage) : 0))
                )?.toFixed(2)) || null,

            unit_count: currentGroup.length,

            // Child elements:
            baseAcres: currentGroup.map(({ _GROUP_KEY, plc_yield_weight, ...pfcProps }) => pfcProps)
        }))
        .value();

    const _sorted = _cropTypePracticeCountyList.sort((a, b) =>
        a?.location_state_name?.localeCompare(b.location_state_name)
        || a?.location_county_name?.localeCompare(b.location_county_name)
        || a?.commodity_name?.localeCompare(b.commodity_name)
    )

    return _sorted;
}