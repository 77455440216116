import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Dialog, Menu, MenuItem, Stack, Stepper, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import { EquipmentIcon, FinancialIcon } from 'components/Icons/Icons'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ChatAssistantButton } from 'mui-toolkit/ChatAssistantButton';
import EquipmentCard from './EquipmentCard';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { _getUniqueCountyPractices } from '@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper'
import { UsersApiHelper } from 'api/ApiHelper'
import { getUniqueCropPractices } from 'components/Vault/Farm/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import { EquipmentCard_Edit } from './EquipmentCard_Edit'
import { v4 } from 'uuid'
import { useFormStatus } from 'mui-toolkit/useFormStatus'

export const SYSTEM_PROMPT_EQUIPMENT_INVENTORY = `
You are a customer service agent, responsible for adding customer records into "Tilley" Farm Financial Software.
Please listen for details on new accounts and return the best available information in JSON format, matching the schema provided below. Only return new or affected records.

### Return values:
- Return only JSON. Do not quote the response.
- To delete a record, set "is_active" to "false".  
- Set "id" to "null".
- Set "producer_token" to "null".
- Set "adjusted_value" to "null".
- Set "prior_value" to "null".
- Set "EquipmentInventoryCountyPractices" to [] empty array.
- Set "value" to "0" if not available.
- Set "year" to the current year (2024).
- Set "category" to "Other" if unclear.
- Set "category" to "Excellent" if unclear.
- For "name", expand acronyms for the professional institution name. Search online if necessary.

### Required fields:
Please provide the most likely choice for these required fields:
- year
- name
- category
- condition
- account_name
- account_type
- value

**account_type** can be one of: 
- "Checking Account"
- "Savings Account"
- "CD"
- "Money Market"
- "Investment Account"
- "Retirement Account"
- "Other". 

**category** can be one of:
- "Crops"
- "Livestock"
- "Vehicles"
- "General"

**condition** can be one of: 
- "New"
- "Excellent"
- "Good"
- "Fair"
- "Poor"

**term** is the payment terms, some examples may include:
- "Net 30 days"
- "Net 60 days"
- etc.

### SAMPLE RESPONSE:
'''json
[
    {
        "id": "52b151f0-24c9-40b3-8a77-67e99a692bd8",
        "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
        "year": 2024,
        "name": "2002 Univerth Seed Trailer",
        "category": "Crops",
        "condition": "Excellent",
        "serial_number": "123456",
        "value": 100000,
        "adjusted_value": 10,
        "prior_value": 100,
        "term": "Net 60 Days",
        "is_active": true,
        EquipmentInventoryCountyPractices: [
            {
                "id": "808a3cc7-d1d6-4f57-b07b-95d774af13f4",
                "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
                "commodity_name": "Corn",
                "type_name": null,
                "practice_name": null,
                "is_active": true,
            },
            {
                "id": "492e98e0-bf41-41e6-ad68-ef2cee69067f",
                "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
                "commodity_name": "Cotton",
                "type_name": null,
                "practice_name": null,
                "is_active": true,
            }
        ]
    },
    {
        "id": "f1010ee2-ea13-442d-bf64-1ffdcb76e391",
        "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
        "year": 2024,
        "name": "Irrigation Motors",
        "category": "Crops",
        "condition": "Good",
        "serial_number": "AB-1556-555584",
        "value": 100000,
        "adjusted_value": null,
        "prior_value": null,
        "term": null,
        "is_active": true,
        EquipmentInventoryCountyPractices: [
            {
                "id": "f6f5cabe-c8f5-4db5-a90f-e5f1f262966d",
                "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
                "commodity_name": "Corn",
                "type_name": null,
                "practice_name": "Irrigated",
                "is_active": true,
            },
            {
                "id": "c5d24d78-6d64-48b8-9409-0a927603af33",
                "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
                "commodity_name": "Cotton",
                "type_name": null,
                "practice_name": "Irrigated",
                "is_active": true,
            }
        ]
    },
    {
        "id": "41d1961d-0e42-49c2-ac77-f24826c37a99",
        "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
        "year": 2024,
        "name": "Stripper/Picker",
        "category": "Crops",
        "condition": "New",
        "serial_number": "ST-33323AB",
        "value": 111000,
        "adjusted_value": null,
        "prior_value": null,
        "term": null,
        "is_active": true,
        EquipmentInventoryCountyPractices: [
            {
                "id": "914100c7-c17a-4209-a867-f01a64d4c40b",
                "producer_token": "00000000-24c9-40b3-8a77-67e99a692bd8",
                "commodity_name": "Cotton",
                "type_name": null,
                "practice_name": null,
                "is_active": true,
            }
        ]
    },
]
'''
`;

export default function EquipmentInventoryWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const isPrint = useMediaQuery('print');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true }) && !isPrint;
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);
    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const handleOnChangeObject = async (_equipment) => {
        handleChange(_equipment);
    }

    // ---

    const [isLoading, setIsLoading] = useState(true);
    const [equipment, setEquipment] = useState([]);
    // const [addingEquipment, setAddingEquipment] = useState(null);
    const [editingEquipment, setEditingEquipment] = useState(null);

    const loadEquipment = async () => {
        try {
            const equipmentFilter = {
                producer_token: globalState.producer_token,
                year: currentYear,
                is_active: true,
            };
            const equipment = await UsersApiHelper.selectEquipmentInventory(equipmentFilter, globalState);
            setEquipment(equipment);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setEquipment([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadEquipment();
        }
    }, [globalState?.producer_token, currentYear]);


    const handleAddNewClick = () => {
        setEditingEquipment({ is_active: true });
    }

    const handleEditClick = (equipment) => {
        setEditingEquipment(equipment);
    }

    // --- --- ---

    const [countyPractices, setCountyPractices] = useState([]);
    const [isLoadingPotential, setIsLoadingPotential] = useState(true);
    const loadCountyPractices = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                is_active: true,
                crop_year: currentYear,
            };
            const farms = await UsersApiHelper.selectProducerFarmFieldCrops(producerFilter, globalState);
            const _cp = getUniqueCropPractices(farms);

            const equipmentInventoryFilter = {
                producer_token: globalState.producer_token,
                equipment_inventory_id: editingEquipment?.id,
                is_active: true,
                year: currentYear,
            };
            const equipmentInventoryCountyPractices = await UsersApiHelper.selectEquipmentInventoryCountyPractices(equipmentInventoryFilter, globalState);

            _cp.forEach(cp => cp.is_active = false);
            equipmentInventoryCountyPractices.forEach(cp => {
                const _index = _cp.findIndex(_cp =>
                    _cp.commodity_name === cp.commodity_name
                    && _cp.practice_name === cp.practice_name
                );
                if (_index > -1) {
                    _cp[_index].is_active = true;
                }
            });

            setCountyPractices(_cp);

            setIsLoadingPotential(false);
        } catch (err) {
            setIsLoadingPotential(false);
            setCountyPractices([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && editingEquipment?.id && currentYear) {
            setCountyPractices([]);
            loadCountyPractices();
        }
    }, [globalState?.producer_token, editingEquipment, currentYear]);

    const handleChange = async (equipment) => {
        if (equipment.id == null) {
            equipment.id = v4();
        }
        equipment.year = currentYear;

        UsersApiHelper.updateEquipmentInventory(equipment, globalState)
            .then(() => {
                loadEquipment();
            })
            .catch(err => {
                console.warn(err);
                formStatusActions.setIsFormDirty(true, false);
                enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
            });
    }

    // ---

    return (
        <>
            <WorkspaceHeader
                title='Equipment Inventory'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        // path: '/financial/documents',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton navigation'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((_currentYear, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(_currentYear);
                                        popupState.close();
                                    }}>
                                        {_currentYear}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {false && <CircularProgress />}

                <Stack>
                    <ChatAssistantButton systemPrompt={SYSTEM_PROMPT_EQUIPMENT_INVENTORY} onChangeObject={handleOnChangeObject} />
                </Stack>
            </Stack>

            <Box className='cardContainer'>
                {equipment?.filter(item => item.is_active != false)?.map((currentEquipment, index) =>
                    <EquipmentCard key={index}
                        equipment={currentEquipment}
                        countyPractices={countyPractices}
                        is_lender={globalState?.is_lender}
                        onChange={handleOnChangeObject}
                        editingEquipment={editingEquipment}
                        handleEditClick={handleEditClick} />
                )}

                {!Boolean(equipment?.length) &&
                    <EmptyCard
                        text="No Inventory Defined"
                        secondaryText="Get started by adding equipment"
                        isLoading={isLoading}
                    />}
            </Box>

            <Dialog open={Boolean(editingEquipment != null)}>
                <EquipmentCard_Edit
                    onChange={handleChange} onClose={() => handleEditClick(null)}
                    equipment={editingEquipment}
                    countyPractices={countyPractices}
                    is_lender={globalState?.is_lender} />
            </Dialog>

            <WorkspaceActionBar content={<>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={handleAddNewClick}
                    >
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Equipment
                    </Button>
                </Box>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
