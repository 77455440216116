import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, FormControl, Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FinancialIcon } from 'components/Icons/Icons'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { ValueLabel } from 'mui-toolkit/ValueLabel'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import useFinanceReport from '../useFinanceReport'

export default function IncomeStatementWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const {
        isLoading,

        isLoadingBudget,
        budget,

        loadIncomeStatement,
        isLoadingIncomeStatement,
        incomeStatement,

        isLoadingRatios,
        ratios,
    } = useFinanceReport({
        onError: handleError,
        currentYear
    })

    const calculateContainersRevenue = () => {
        return _.uniq(incomeStatement?.revenue?.values?.map(v => v.container));
    }

    const calculateContainersExpenses = () => {
        return _.uniq(incomeStatement?.expenses?.values?.map(v => v.container));
    }

    // --- --- ---

    const handleChangeState_LineItem = async (event, _currentDocumentDictionary) => {
        if (event?.target?.value != incomeStatement?.values.find(v => v.id == _currentDocumentDictionary?.id)?.value) {
            formStatusActions.setIsFormDirty(true, true);

            const _newValue = {
                ..._currentDocumentDictionary,
                id: _currentDocumentDictionary?.id || uuidv4(),
                value: event?.target?.value || 0
            }

            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                Document: {
                    id: _newValue?.document_id || uuidv4(),
                    values: [_newValue]
                }
            };

            await UsersApiHelper.updateFinancialDocument(incomeStatement, globalState);

            UsersApiHelper.updateDocumentDictionary(_uploadFormData, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadIncomeStatement();
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    const formatName = (value) => {
        if (value) {
            return value.replaceAll("_", " ")
        }
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Income Statement'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        // path: '/financial/documents',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoading && <CircularProgress />}
                {formStatusComponent}
            </Stack>

            <Grid container columnSpacing={6} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Revenue from Operations</Typography>

                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                            Farm Revenue
                        </Typography>
                        <Grid container spacing={2}>
                            {Boolean(budget?.budgetCommodities?.length)
                                && budget?.budgetCommodities
                                    ?.sort((a, b) =>
                                        a.commodity_name?.localeCompare(b.commodity_name)
                                    )
                                    ?.map((currentCommodity, index) =>
                                        <Grid item key={index} xs={6}>
                                            <FormControl fullWidth={true}>
                                                <StatefulControlledTextField
                                                    label={formatName(currentCommodity?.commodity_name)}
                                                    name={currentCommodity?.commodity_name}
                                                    isRequired={false}
                                                    defaultValue={formatNumber(currentCommodity.revenue, 0)}
                                                    variant="outlined"
                                                    disabled={true}
                                                    onChange={(e) => handleChangeState_LineItem(e, v)}
                                                    InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                    maskType={MASK_NUMERIC}
                                                />
                                            </FormControl>

                                            <br />
                                        </Grid>
                                    )}
                        </Grid>
                    </Box>

                    {calculateContainersRevenue()?.map(container =>
                        <Box key={container} sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                                {formatName(container)}
                            </Typography>

                            <Grid container spacing={2}>
                                {Boolean(incomeStatement?.values?.length)
                                    && incomeStatement?.values?.filter(v => v.container == container).map((v, index) =>
                                        <Grid item key={v.item} xs={6}>
                                            <FormControl fullWidth={true}>
                                                <StatefulControlledTextField
                                                    label={formatName(v.item)}
                                                    name={v.item}
                                                    isRequired={false}
                                                    defaultValue={v.value}
                                                    variant="outlined"
                                                    onChange={(e) => handleChangeState_LineItem(e, v)}
                                                    InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                    maskType={MASK_NUMERIC}
                                                />
                                            </FormControl>

                                            <br />
                                        </Grid>
                                    )}
                            </Grid>
                        </Box>
                    )}

                    <Typography variant="h6">
                        Totals
                    </Typography>
                    <Box className="flexWrapContainer">
                        <ValueLabel label="Total Revenue from Operations" value={formatPrice(ratios?.revenue_operations, 0)} isLoading={isLoadingRatios} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} className='pageBreakBefore'>
                    <Typography variant="h6" sx={{ mb: 2 }}>Operating Costs & Expenses</Typography>

                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                            Production Costs
                        </Typography>
                        <Grid container spacing={2}>
                            {Boolean(budget?.budgetCommodities?.length)
                                && budget?.budgetCommodities
                                    ?.sort((a, b) =>
                                        a.commodity_name?.localeCompare(b.commodity_name)
                                    )
                                    ?.map((currentCommodity, index) =>
                                        <Grid item key={index} xs={6}>
                                            <FormControl fullWidth={true}>
                                                <StatefulControlledTextField
                                                    label={formatName(currentCommodity?.commodity_name)}
                                                    name={currentCommodity?.commodity_name}
                                                    isRequired={false}
                                                    defaultValue={formatNumber(currentCommodity.production_cost, 0)}
                                                    variant="outlined"
                                                    disabled={true}
                                                    onChange={(e) => handleChangeState_LineItem(e, v)}
                                                    InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                    maskType={MASK_NUMERIC}
                                                />
                                            </FormControl>

                                            <br />
                                        </Grid>
                                    )}
                        </Grid>
                    </Box>

                    {calculateContainersExpenses()?.map(container =>
                        <Box key={container} sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                                {formatName(container)}
                            </Typography>

                            <Grid container spacing={2}>
                                {Boolean(incomeStatement?.values?.length)
                                    && incomeStatement?.values?.filter(v => v.container == container).map((v, index) =>
                                        <Grid item key={index} xs={6}>
                                            <FormControl fullWidth={true}>
                                                <StatefulControlledTextField
                                                    label={formatName(v.item)}
                                                    name={v.item}
                                                    isRequired={false}
                                                    defaultValue={v.value}
                                                    variant="outlined"
                                                    onChange={(e) => handleChangeState_LineItem(e, v)}
                                                    InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                    maskType={MASK_NUMERIC}
                                                />
                                            </FormControl>

                                            <br />
                                        </Grid>
                                    )}
                            </Grid>
                        </Box>
                    )}

                    <Typography variant="h6">
                        Totals
                    </Typography>
                    <Box className="flexWrapContainer">
                        <ValueLabel label="Total Costs and Expenses" value={formatPrice(budget?.expenses?.total + incomeStatement?.expenses?.total, 0)} sx={{ fontWeight: "bold !important" }} isLoading={isLoadingRatios} />
                    </Box>
                </Grid>
            </Grid>

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
