import { AppContext } from 'App'
import { ArcPlcService } from 'components/ArcPlc/ArcPlcService'
import { BudgetService } from 'components/Budget/BudgetService'
import { BUDGET_STATUS_ACTIVE } from 'components/Budget/helpers'
import { InsuranceService } from 'components/Insurance/InsuranceService'
import { MODULE_ARC_PLC, MODULE_FINANCIAL, MODULE_INSURANCE, SECTION_FINANCIAL_SCHEDULE_F } from 'components/Menu/NavigationMenu'
import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FinancialService } from './FinancialService'

export const ERROR_NO_BUDGET_SPECIFIED = "No active budget is available.  Please create an active budget and try again.";

export default function useFinanceReport({
    onError,
    currentYear,

    loadModules,

    // Override api/globalState:
    producerState
}) {
    const { globalState } = producerState ? { globalState: producerState } : useContext(AppContext);

    const [isLoading, setIsLoading] = useState(true);

    // --- --- ---

    const { actions: arcPlcActions } = new ArcPlcService({
        apiConfig: globalState,
        crop_year: currentYear
    });
    const { actions: insuranceActions } = new InsuranceService({
        crop_year: currentYear,
        status: BUDGET_STATUS_ACTIVE,
        apiConfig: globalState
    });
    const { actions: budgetActions } = new BudgetService({
        apiConfig: globalState,
        year: currentYear
    });
    const { actions: financialActions } = new FinancialService({
        apiConfig: globalState,
        year: currentYear
    });

    // --- --- ---

    const [balanceSheet, setBalanceSheet] = useState(null);
    const [isLoadingBalanceSheet, setIsLoadingBalanceSheet] = useState(true);
    const loadBalanceSheet = async () => {
        try {
            setIsLoading(true);
            setIsLoadingBalanceSheet(true);

            const _balanceSheet = await financialActions.getBalanceSheet();
            setBalanceSheet(_balanceSheet);

            setIsLoadingBalanceSheet(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingBalanceSheet(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            loadBalanceSheet();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [balanceSheetPriorYear, setBalanceSheetPriorYear] = useState(null);
    const [isLoadingBalanceSheetPriorYear, setIsLoadingBalanceSheetPriorYear] = useState(true);
    const loadBalanceSheetPriorYear = async () => {
        try {
            setIsLoading(true);
            setIsLoadingBalanceSheetPriorYear(true);

            const _balanceSheetPriorYear = await financialActions.getBalanceSheetPriorYear();
            setBalanceSheetPriorYear(_balanceSheetPriorYear);

            setIsLoadingBalanceSheetPriorYear(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingBalanceSheetPriorYear(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            loadBalanceSheetPriorYear();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [incomeStatement, setIncomeStatement] = useState(null);
    const [isLoadingIncomeStatement, setIsLoadingIncomeStatement] = useState(true);
    const loadIncomeStatement = async () => {
        try {
            setIsLoadingIncomeStatement(true);
            setIsLoading(true);

            const _incomeStatement = await financialActions.getIncomeStatement();
            setIncomeStatement(_incomeStatement);

            setIsLoadingIncomeStatement(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingIncomeStatement(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            loadIncomeStatement();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [budget, setBudget] = useState(null);
    const [isLoadingBudget, setIsLoadingBudget] = useState(true);
    const loadBudget = async () => {
        try {
            setIsLoadingBudget(true);
            setIsLoading(true);

            const budget = await budgetActions.getBudget();
            if (budget) {
                setBudget(budget);
            } else {
                onError && onError(ERROR_NO_BUDGET_SPECIFIED);
                setIsLoadingRatios(false);
            }

            setIsLoadingBudget(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingBudget(false);
            setIsLoadingRatios(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) && globalState?.producer_token && currentYear) {
            loadBudget();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [arcPlc, setArcPlc] = useState(null);
    const [isLoadingArcPlc, setIsLoadingArcPlc] = useState(true);
    const loadArcPlc = async () => {
        try {
            setIsLoadingArcPlc(true);
            setIsLoading(true);

            const arcPlc = await arcPlcActions.getArcPlc();
            setArcPlc(arcPlc);

            setIsLoadingArcPlc(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingArcPlc(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_ARC_PLC)) {
            loadArcPlc();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [insuranceScenario, setInsuranceScenario] = useState(null);
    const [insuranceScenarioTimestamp, setInsuranceScenarioTimestamp] = useState(uuidv4());
    const [isLoadingInsuranceScenario, setIsLoadingInsuranceScenario] = useState(true);
    const loadInsuranceScenario = async () => {
        try {
            setIsLoadingInsuranceScenario(true);
            setInsuranceScenarioTimestamp(uuidv4());

            const _insuranceScenarioPopulated = await insuranceActions.loadInsuranceScenario();

            if (_insuranceScenarioPopulated?.length
                && _insuranceScenarioPopulated[0].budgetCountyPractices?.length
                && _insuranceScenarioPopulated[0].totals?.insurancePlans?.length
            ) {
                setInsuranceScenario(_insuranceScenarioPopulated[0]);
                setInsuranceScenarioTimestamp(uuidv4());

                insuranceActions.calculatePremiums(_insuranceScenarioPopulated[0])
                    .then((calculatePremiumsResponseInsuranceScenario) => {
                        setInsuranceScenario(calculatePremiumsResponseInsuranceScenario);
                        setInsuranceScenarioTimestamp(uuidv4());
                        setIsLoadingInsuranceScenario(false);
                    })
            } else {
                onError && onError("No insurance plans are selected. Please make your insurance elections.");
                setInsuranceScenario(null);
                setInsuranceScenarioTimestamp(uuidv4());
                setIsLoadingInsuranceScenario(false);
            }
        } catch (err) {
            setIsLoadingInsuranceScenario(false);
            setInsuranceScenario(null);
            setInsuranceScenarioTimestamp(uuidv4());

            setIsLoadingRatios(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            if ((!loadModules?.length) || loadModules.includes(MODULE_INSURANCE)) {
                loadInsuranceScenario();
            }
        } else {
            setInsuranceScenario(null);
            setInsuranceScenarioTimestamp(uuidv4());
        }
    }, [globalState?.producer_token, currentYear]);

    // --- --- ---

    const [scheduleF, setScheduleF] = useState(null);
    const [isLoadingScheduleF, setIsLoadingScheduleF] = useState(true);
    const loadScheduleF = async () => {
        try {
            setIsLoading(true);
            setIsLoadingScheduleF(true);

            const _scheduleF = await financialActions.getScheduleF();
            setScheduleF(_scheduleF);

            setIsLoadingScheduleF(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingScheduleF(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL) || loadModules.includes(SECTION_FINANCIAL_SCHEDULE_F)) {
            loadScheduleF();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [equipmentInventory, setEquipmentInventory] = useState(null);
    const [isLoadingEquipmentInventory, setIsLoadingEquipmentInventory] = useState(true);
    const loadEquipmentInventory = async () => {
        try {
            setIsLoading(true);
            setIsLoadingEquipmentInventory(true);

            const _equipmentInventory = await financialActions.getEquipmentInventory();
            setEquipmentInventory(_equipmentInventory);

            setIsLoadingEquipmentInventory(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingEquipmentInventory(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            loadEquipmentInventory();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [debtSchedule, setDebtSchedule] = useState(null);
    const [isLoadingDebtSchedule, setIsLoadingDebtSchedule] = useState(true);
    const loadDebtSchedule = async () => {
        try {
            setIsLoading(true);
            setIsLoadingDebtSchedule(true);

            const _debtSchedule = await financialActions.getDebtSchedule();
            setDebtSchedule(_debtSchedule);

            setIsLoadingDebtSchedule(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingDebtSchedule(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            loadDebtSchedule();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [loanOriginationMemo, setLoanOriginationMemo] = useState(null);
    const [isLoadingLoanOriginationMemo, setIsLoadingLoanOriginationMemo] = useState(true);
    const loadLoanOriginationMemo = async () => {
        try {
            setIsLoading(true);
            // setIsLoadingLoanOriginationMemo(true);

            const _loanOriginationMemo = await financialActions.getLoanOriginationMemo();
            setLoanOriginationMemo(_loanOriginationMemo);

            setIsLoadingLoanOriginationMemo(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoadingLoanOriginationMemo(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            loadLoanOriginationMemo();
        }
    }, [globalState?.producer_token, currentYear])

    // --- --- ---

    const [isLoadingRatios, setIsLoadingRatios] = useState(true);
    const [ratios, setRatios] = useState(null);

    const loadRatios = () => {
        try {
            setIsLoadingRatios(true);

            const _ratios = financialActions.getRatios({
                budget,
                arcPlc,
                insuranceScenario,
                incomeStatement,
                balanceSheet, balanceSheetPriorYear
            });

            setRatios(_ratios);
            setIsLoadingRatios(false);
        } catch (err) {
            setRatios(null);
            setIsLoadingRatios(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token
            && budget && arcPlc && insuranceScenario
            && balanceSheet && incomeStatement
        ) {
            if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
                loadRatios();
            }
        } else {
            setRatios(null);
        }
    }, [budget, arcPlc, insuranceScenario, balanceSheet, incomeStatement, globalState?.producer_token]);

    // --- --- ---

    const [debtRatios, setDebtRatios] = useState(null);
    const [isLoadingDebtRatios, setIsLoadingDebtRatios] = useState(true);
    const loadDebtRatios = () => {
        try {
            if (balanceSheet && incomeStatement && arcPlc && insuranceScenario && debtSchedule && ratios && loanOriginationMemo) {
                setIsLoading(true);
                setIsLoadingDebtRatios(true);

                const _ratios = financialActions.getDebtRatios({
                    balanceSheet,
                    incomeStatement,
                    arcPlc,
                    insuranceScenario,
                    debtSchedule,
                    loanOriginationMemo,
                    financeRatios: ratios
                });

                setDebtRatios(_ratios);

                setIsLoadingDebtRatios(false);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoadingDebtRatios(false);
            setIsLoading(false);

            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if ((!loadModules?.length) || loadModules.includes(MODULE_FINANCIAL)) {
            if (balanceSheet && incomeStatement && debtSchedule && arcPlc && insuranceScenario && ratios && loanOriginationMemo) {
                loadDebtRatios();
            }
        }
    }, [globalState?.producer_token, currentYear, balanceSheet, incomeStatement, arcPlc, insuranceScenario, debtSchedule, ratios, loanOriginationMemo])

    // --- --- ---

    return {
        isLoading,

        isLoadingBudget,
        budget,
        loadBudget,

        arcPlc,
        isLoadingArcPlc,
        loadArcPlc,

        isLoadingInsuranceScenario,
        insuranceScenario,
        loadInsuranceScenario,

        balanceSheet,
        isLoadingBalanceSheet,
        loadBalanceSheet,
        setBalanceSheet,

        balanceSheetPriorYear,
        isLoadingBalanceSheetPriorYear,
        loadBalanceSheetPriorYear,

        incomeStatement,
        isLoadingIncomeStatement,
        loadIncomeStatement,

        scheduleF,
        isLoadingScheduleF,
        loadScheduleF,

        equipmentInventory,
        isLoadingEquipmentInventory,
        loadEquipmentInventory,

        debtSchedule,
        isLoadingDebtSchedule,
        loadDebtSchedule,

        loanOriginationMemo,
        isLoadingLoanOriginationMemo,
        loadLoanOriginationMemo,

        debtRatios,
        isLoadingDebtRatios,
        loadDebtRatios,

        isLoadingRatios,
        ratios,
        loadRatios,
    }
}
